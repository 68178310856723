const recorder = {
  state: () => ({
      currentTime: 0, //录像流的当前时间 s
      flowDisconnection: false, //录像播放完毕或者流断开
      pause: false //暂停状态
  }),
  mutations: {
      setCurrentTime(state: any, value: number) {
          state.currentTime = value
      },
      setStreamState(state: any, value: boolean) {
          state.flowDisconnection = value
      },
      setStreamPause(state: any, value: boolean) {
          state.pause = value
      }
  }
}
export default recorder

const daraggleModule = {
  state: () => ({
    position: [0, 0],
    menuVisible: false
  }),
  mutations: {
    setMenuVisible(state: any, data: boolean) {
      console.log(data)

      state.menuVisible = data
    }
  }
}
export default daraggleModule

const alarmModule = {
  state: () => ({
    newInfo: [], //最新告警
    isInfrared: 0 // 摄像机红外切换
  }),
  mutations: {
    setNewInfo(state: any, data: any[]) {
      state.newInfo = data
    },
    setIsInfrared(state: any, data: number) {
      state.isInfrared = data
    }
  }
}
export default alarmModule

<!--
 * @Descripttion: 
 * @version: 
 * @Author: huangjunjie
 * @Date: 2024-03-01 09:45:32
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-05-30 12:02:11
-->
<template>
  <div class="header">
    <div class="header_lft">
      <img v-errorImg="state.logo.configSmallImage" :src="state.logo.configSmallImage" style="vertical-align: middle" alt="" />
      {{ state.logo.headName }}
    </div>
    <div class="header_mdl">
      <TyNav :data="menu" />
    </div>
    <div class="header_rgt">
      <HeaderRight />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue'
import TyNav from '@/components/TyNav/index.vue'
import HeaderRight from './HeaderRight.vue'

import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { selectSysConfig } from './service'
import { ElMessage } from 'element-plus'
const router = useRouter()
const store = useStore()
const state = reactive<{ [name: string]: any }>({
  list: [],
  audioSrc: '@/assets/radio/aqd.wav',
  visible: store.state.draggle.menuVisible,
  timers: [],
  logo: {}
})

const getSysConfig = async () => {
  const res = await selectSysConfig()
  if (res.data.code == 0) {
    state.logo = res.data.data
    store.commit('setLogoConfig', state.logo)
  } else {
    ElMessage.warning('获取标题失败')
  }
}

const menu = computed(() => {
  return store.state.user.routes
})

onMounted(async () => {
  await getSysConfig()
})
</script>
<style lang="scss" scoped>
.header {
  height: 60px;
  line-height: 60px;
  width: 100%;
  overflow: hidden;
  display: flex;
  background: linear-gradient(180deg, #051427 0%, #182731 100%);
  background-size: 100% 60px;

  .header_lft {
    padding-left:  20px;
    box-sizing: border-box;
    flex: 0 0 460px;
    background-image: url('@/assets/ui-image/zhengning/header_bg2.png');
    background-size: 460px 60px;
    background-repeat: no-repeat;
    font-size: 32px;
    font-family: 'CloudZongYiGBK';
    // font-weight: bold;
    // letter-spacing: 0.15em;
    letter-spacing: 6px;
    text-shadow: 0 0 20px var(--el-color-primary);
    img {
      height: 40px;
      width: 40px;
    }
  }

  .header_mdl {
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
    // padding-right: 100px;
    position: relative;
  }

  .header_rgt {
    flex: 0 0 130px;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
</style>

import { createStore, Store } from 'vuex'
import mqttModule from './mqtt'
import userModule from './user'
import alarmModule from './alarm'
import draggleModel from './draggle'
import PollingModel from './polling'
import RegionTreeModel from './regionTree'
import recorder from './recording'
const store: any = createStore({
  state() {
    return { count: 0 }
  },
  modules: {
    mqtt: mqttModule,
    user: userModule,
    alarm: alarmModule,
    draggle: draggleModel,
    polling: PollingModel,
    regionTree: RegionTreeModel,
    recorder: recorder
  }
})
export { store }
export default (app: any): any => {
  app.use(store)
}

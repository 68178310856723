/*
 * @Descripttion:
 * @version:
 * @Author: huangjunjie
 * @Date: 2024-04-01 14:23:52
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-05-16 19:28:18
 */
import { login, getUser, apiGetRefreshToken, logout } from '@/views/admin/service'
import { apiGetRoutes } from '@/layout/service'
const LoginModule = {
  state: () => ({
    hasGetPermission: false,    // 判断是否已经获取过权限接口，在登录时以及router前置导航中使用
    token: localStorage.getItem('authorization'),   // 接口token
    refreshToken: localStorage.getItem('refreshToken'),   // 刷新token
    isPlatformtoken: localStorage.getItem('isPlatformtoken') ? true : false,  // 判断是否是第三方平台
    userInfo: {
      name: '',
      avatar: ''
    },
    permissions: [],
    roles: [],
    routes: [],
    logoConfig: {
      globalVoiceSwitch: '0'
    }
  }),
  mutations: {
    SET_HAS_GET_PERMISSON: (state: any, hasGetPermission: boolean) => {
      state.hasGetPermission = hasGetPermission 
    },
    SET_PLATFORMTOKEN: (state: any, isPlatformtoken: boolean) => {
      state.isPlatformtoken = isPlatformtoken
    },
    SET_TOKEN: (state: any, token: any) => {
      state.token = token
    },
    SET_REFRESH_TOKEN: (state: any, refreshToken: string) => {
      state.refreshToken = refreshToken
    },
    SET_ROUTES: (state: any, routes: any) => {
      state.routes = routes
    },
    SET_PERMISSIONS: (state: any, permissions: any) => {
      state.permissions = permissions
    },
    SET_ROLES: (state: any, roles: any) => {
      state.roles = roles
    },
    SET_USERINFO: (state: any, userinfo: any) => {
      state.userInfo = userinfo
    },
    setLogoConfig: (state: any, logoConfig: any) => {
      state.logoConfig = logoConfig
    }
  },
  actions: {
    login({ commit }: any, form: { username: string; password: string }) {
      return new Promise((resolve, reject) => {
        login(form).then(async (res) => {
          if (res.data.code == 0) {
            commit('SET_TOKEN', res.data?.token)
            localStorage.setItem('authorization', res.data?.token)
            resolve(res)
          } else {
            resolve(res)
          }
        })
      })
    },
    loginout({ commit }: any) {
      return new Promise((resolve, reject) => {
        logout().then(async (res) => {
          if (res.data.code == 0 || res.data.code == 200) {
            // 删除token
            commit('SET_HAS_GET_PERMISSON', false)
            commit('SET_TOKEN', null)
            commit('SET_REFRESH_TOKEN', null)
            localStorage.removeItem('authorization')
            localStorage.removeItem('refreshToken')
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    async getUserInfo({ commit, state }: any) {
      const res = await getUser()
      if (res.data.code == 0) {
        commit('SET_USERINFO', res.data.user)
        commit('SET_ROLES', res.data.roles)
        commit('SET_PERMISSIONS', res.data.permissions)
        return true
      } else {
        return false
      }
    },
    async getRoutes({ commit, state }: any) {
      const res = await apiGetRoutes()
      if (res.data.code == 0) {
        // 保留目录
        const routes = res.data.data.filter((item: any) => item.menuType && item.menuType == 'M')
        commit('SET_ROUTES', routes)
        return routes
      } else {
        return []
      }
    }
  }
}

/**
 * 深度过滤数组
 * @param arr 数组
 * @param callback 过滤条件
 * @returns 过滤后的数组
 */
const deepFilter = (arr: Array<any>, callback: (item: any, index: number, arr: Array<any>) => boolean) => {
  return arr.filter((item, index, array) => {
    if (item['children']?.length) {
      item['children'] = deepFilter(item['children'], callback)
    }
    return callback(item, index, array)
  })
}

export default LoginModule

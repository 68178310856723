<template>
  <div class="ty-nav">
    <div :class="['ty-nav-item animate__animated animate__pulse', acClassFuc(i)]" v-for="(i, idx) in data" :key="idx"
      @click="navClick(i)">{{ i?.meta?.title }}</div>
    <div class="ty-nav-item animate__animated animate__pulse" v-if="list.length" ref="buttonRef">
      <el-space alignment="center" direction="horizontal" style="height: 30px" :size="0">
        <el-icon :size="16">
          <MoreFilled />
        </el-icon>
        <el-icon :size="16">
          <MoreFilled />
        </el-icon>
      </el-space>
      <el-popover popper-style="color: white;background-color: transparent;border: none;width: 165px; box-shadow: none"
        popper-class="opover" effect="dark" ref="popoverRef" :show-arrow="false" :virtual-ref="buttonRef"
        trigger="hover" virtual-triggering>
        <div :class="['ty-nav-item ty-admin-item', acClassFuc(i)]" v-for="(i, idx) in list" :key="idx"
          @click="navClick(i)">{{ i?.meta?.title }}</div>
      </el-popover>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, nextTick, ref, ComputedRef } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { debounce } from 'lodash'
import { MoreFilled } from '@element-plus/icons-vue'

const router = useRouter()
const route = useRoute()
const props = defineProps<{ data: any[] }>()
const data = ref<any[]>([])
const list = ref<any[]>([])
const buttonRef = ref(null)
const navClick = (e: { path: string }) => {
  const routeName = findFirstRoute(e)
  if (routeName) {
    router.push({ name: routeName })
  }
}

// 找到第一个最深层的子级路由
const findFirstRoute = (item: any) => {
  let routeName = ''
  if (item?.children?.length) {
    routeName = findFirstRoute(item.children[0])
  } else {
    routeName = item.name
  }
  return routeName
}

const acClassFuc = (i) => {
  return currentPath.value.indexOf(i.name) !== -1 ||
    (currentPath.value == 'system' && i.path == '/system') ? 'ty-nav-item_s' : ''
}

const currentPath = computed(() => {
  return route.matched[0].name || route.matched[route.matched.length - 1].path || ''
}) as ComputedRef<string>

onMounted(() => {
  DynamicC()
  window.addEventListener('resize', DynamicC)
})
const DynamicC = debounce(async () => {
  await nextTick()
  let TyNav: any = document.getElementsByClassName('ty-nav')[0]
  let w = TyNav.offsetWidth
  const re = Math.floor(w / 135)
  if (props.data.length > re) {
    list.value = props.data.slice(re - 1, props.data.length)
    data.value = props.data.slice(0, re - 1)
  } else {
    list.value = []
    data.value = props.data
  }
}, 200)
</script>
<style lang="scss" scoped>
.ty-nav {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  color: white;
}

.ty-nav-item {
  flex: 0 0 131px;
  line-height: 33px;
  font-family: 'YouSheBiaoTiHei';
  font-size: 22px;
  text-align: center;
  // background-image: url('../../assets/ui-image/header/btn.png');
  background-image: url('@/assets/ui-image/zhengning/btn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-right: 10px;

  &:hover {
    @extend .ty-nav-item_s
  }
}

.ty-nav-item_s {
  // background-image: url('../../assets/ui-image/header/btn-s.png');
  background-image: url('@/assets/ui-image/zhengning/btn_active.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ty-admin-item {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>

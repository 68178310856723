import { store } from '@/store'
import { computed } from 'vue'

//巡视 算法/类型处理
export const algorithmnTypeFilter = (
  list: Array<any>,
  algorithmnName: string,
  algorithmnType?: string | any
) => {
  console.log('list', list.length)

  if (algorithmnType) {
    if (list && list.length != 0) {
      let algorithmnGroup: any = list.filter(
        (item: { enName: string }) => (item.enName = algorithmnName)
      )
      if (algorithmnGroup.children.length != 0) {
        let typeGroup = algorithmnGroup.children.filters(
          (item: { enName: string }) => (item.enName = algorithmnType)
        )
        if (typeGroup.length != 0) {
          return typeGroup[0]['cnName']
        }
      }
    }
    return algorithmnType
  } else {
    if (list && list.length != 0) {
      let algorithmnGroup = list.filter(
        (item: { enName: string }) => (item.enName = algorithmnName)
      )
      if (algorithmnGroup.length != 0) {
        return algorithmnGroup[0]['cnName']
      }
    }
    return algorithmnName
  }
}
//过滤树状结构
export const treeFilter = (tree: Array<any>, id: string) => {
  for (let key in tree) {
    if (tree[key]['id'] == id) {
      console.log('active', tree[key], id)
      let active: any = Object.assign({}, tree[key])
      return active
    }
    if (tree[key].children) {
      let active: any = treeFilter(tree[key].children, id)
      if (active) {
        return active
      }
    }
  }
}
//过滤树状结构
export const treeRobotFilter = (tree: Array<any>, id: any) => {
  let obj = {}

  function deep(tree: any, id: any) {
    tree.map((item: any) => {
      if (item.terminalMac && item.terminalMac == id) {
        obj = {
          channelOne: item.channelOne,
          channelTwo: item.channelTwo,
          mapUrl: item.mapUrl
        }
        return
      }
      if (item.children) {
        deep(item.children, id)
      }
    })
  }

  deep(tree, id)
  return obj
}

// 过滤接口的字段替换(equipmentName to regionName)为了配合el-cascader选择器使用
export const filterDeviceName = (tree: Array<any>) => {
  tree.map((item) => {
    item.equipmentName && (item['regionName'] = item.equipmentName)
    if (item.children) {
      return filterDeviceName(item.children)
    }
    return item
  })
  return tree
}

// 通过设备id获取对应父级的id和name

export const RegionIdAndNameByequipmentId = (tree: Array<any>, id: string) => {
  console.log(tree, id)
  const data = { regionId: '', regionName: '' }

  function ByequipmentId(tree: Array<any>, id: string) {
    tree.map((item) => {
      if (item.equipmentName && item.id === id) {
        data.regionId = item.regionId
      }
      if (item.children) {
        ByequipmentId(item.children, id)
      }
    })
  }

  ByequipmentId(tree, id)

  function ByequipmentId2(tree: Array<any>) {
    tree.map((item) => {
      if (data.regionId === item.id) {
        data.regionName = item.regionName
      }
      if (item.children) {
        ByequipmentId2(item.children)
      }
    })
  }

  ByequipmentId(tree, id)
  ByequipmentId2(tree)
  return data
}
export function subFloatToInt(arg1: number, arg2: number): any {
  var r1, r2, m, n
  try {
      r1 = arg1.toString().split('.')[1].length
  } catch (e) {
      r1 = 0
  }
  try {
      r2 = arg2.toString().split('.')[1].length
  } catch (e) {
      r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

// 过滤两层tree结构
export const filterTwoTree = (tree: Array<any>) => {
  return tree.map((item) => {
    item.children = item.children.map((itemm: any) => {
      const obj: any = {}
      for (const key in itemm) {
        if (key !== 'children') {
          obj[key] = itemm[key]
        }
      }
      return obj
    })
    return item
  })
}

// 过滤掉区域没有子区域的禁用
export const filterTwoTreeDisable = (tree: Array<any>) => {
  return tree.map((item) => {
    item.children = item.children.map((itemm: any) => {
      if (itemm.pid == '1' && !Array.isArray(itemm.children)) {
        itemm['disabled'] = true
      }
      return itemm
    })
    return item
  })
}

// 多边形点位组计算质心点坐标
function Area(p0: any, p1: any, p2: any) {
  let area: any = 0
  area =
    p0.x * p1.y +
    p1.x * p2.y +
    p2.x * p0.y -
    p1.x * p0.y -
    p2.x * p1.y -
    p0.x * p2.y
  return area / 2
}

//line 249 计算polygon的质心
export const getPolygonAreaCenter = (points: any) => {
  if (points.length < 3) {
    return points[0]
  } else {
    let sum_x: any = 0
    let sum_y: any = 0
    let sum_area: any = 0
    let p1: any = points[1]
    for (let i = 2; i < points.length; i++) {
      const p2 = points[i]
      const area = Area(points[0], p1, p2)
      sum_area += area
      sum_x += (points[0].x + p1.x + p2.x) * area
      sum_y += (points[0].y + p1.y + p2.y) * area
      p1 = p2
    }
    const x: any = sum_x / sum_area / 3
    const y: any = sum_y / sum_area / 3
    return { x, y }
  }
}

// 定义一个深拷贝函数  接收目标target参数
const deepClone = (target: any) => {
  // 定义一个变量
  let result: any
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (const i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {}
      for (const i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}

export { default as Filter } from './filterTree'

// 过滤掉区域没有子区域的禁用
export const filterDisable = (tree: any) =>
  tree.map((item: any) => {
    if (item.treeType && item.treeType !== '3') {
      item.disabled = true
    }
    if (item.children && item.children.length) {
      item.children = filterDisable(item.children)
    }
    return item
  })

/**
 * 根据总条数计算页码
 * @param {number} count 总条数
 * @param {number} pageSize 每页条数
 * @returns
 */
export const pagerCount = (count: any, pageSize: any) => {
  if (typeof count == 'number') {
    if (count > 0) {
      try {
        let _pagerCount: any =
          count % pageSize == 0 ? count / pageSize : count / pageSize + 1
        const c = _pagerCount.toFixed(0) //小数取整
        _pagerCount = c > _pagerCount ? c - 1 : c //过滤四舍五入
        return _pagerCount
      } catch (error) {
        return 0
      }
    } else {
      return 0
    }
  } else {
    return 0
  }
}

/**
 * number转中文大写
 * @param num 1 to 一
 * @returns string
 */
export const numberToChinese = (num: number) => {
  const Number = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
  const Unit = new Array("", "十", "百", "千", "万", "亿", "点", "");
  // 分开小数和整数部分
  const a = ("" + num).replace(/(^0*)/g, "").split(".");
  // 关键节点单位判断
  let k = 0;
  // 最终转化的字符
  let result = "";
  // 整数部分处理
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        result = Unit[7] + result;
        break;
      case 4:
        if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$").test(a[0])) {
          result = Unit[4] + result;
        }
        break;
      case 8:
        result = Unit[5] + result;
        Unit[7] = Unit[5];
        k = 0;
        break;
    }

    if (k % 4 == 2 && a[0].charAt(i + 2) != '0' && a[0].charAt(i + 1) == '0') {
      result = Number[0] + result;
    }
    if (a[0].charAt(i) != '0') {
      // @ts-ignore
      result = Number[(a[0].charAt(i)) % 10] + Unit[k % 4] + result;
    }
    k++;
  }

  // 加上小数部分(如果有小数部分)
  if (a.length > 1) {
    result += Unit[6];
    for (let i = 0; i < a[1].length; i++) {
      // @ts-ignore
      result += Number[a[1].charAt(i)];
    }
  }
  if (result == '壹十') {
    result = "十";
  }
  if (result.match(/^壹/) && result.length == 3) {
    result = result.replace("壹", "");
  }
  return result;
};

// 获取url参数
export const getUrl = (url: string) => {
  const arr = url.split('?');
  const params: Array<any> = arr[1]?.split('&') || [];
  const obj: any = {};
  for(let i=0;i<params.length;i++){
    const param = params[i].split('=');
    obj[param[0]] = param[1];
  }
  return obj;
}

/**
 * 处理权限异常情况需要跳转到登录页的情况
 * 1、当 isPlatformtoken = true，跳转到第三方平台登录页
 * 2、否则跳转到 /login 登录页
 * @param {boolean} isPlatformtoken 是否跳转到第三方平台登录页
 */
export const handleJumpToLogin = (isPlatformtoken: boolean = false) => {
  const host = window.location.host
  let url = ''
  if (isPlatformtoken) {
    // url = 'https://10.92.41.221:31101/portal/login/'
    url = 'https://10.71.58.22:32001/portal/login/'
  } else {
    url = import.meta.env.DEV ? `http://${host}/#/login` : `https://${host}/#/login`
  }
  store.commit('SET_HAS_GET_PERMISSON', false)
  store.commit('SET_PLATFORMTOKEN', false)
  store.commit('SET_TOKEN', null)
  store.commit('SET_REFRESH_TOKEN', null)
  store.commit('SET_ROUTES', [])
  localStorage.removeItem('isPlatformtoken')
  localStorage.removeItem('authorization')
  localStorage.removeItem('refreshToken')
  window.location.href = url
}

/**
 * 判断某个路由是否有访问权限
 * @param route 权限路由
 * @param routes 权限路由集
 * @returns true or false
 */
export const hasPermission = (route: string, routes: Array<any>) => {
  if(route?.[0] == '/') {
    // 敲除开头的 /
    route = route.slice(1)
  }
  const levelRoute = route.split('/')
  return routes.some(d => {
    let temp: string | null = null
    if(d?.path?.[0] == '/') {
      // 敲除开头的 /
      temp = d?.path?.slice(1)
    }
    if(temp) {
      return levelRoute[0].includes(temp)
    } else {
      return false
    }
  })
}

/**
 * 判断是否第三方平台
 */
export const isPlatformtoken = computed(() => {
  const platformFlag = localStorage.getItem('isPlatformtoken') ? true : false
  return store.state.user.isPlatformtoken || platformFlag
})

/**
 * 获取token
 * @returns 
 */
export const getToken = () => {
  return store.state.user.token || localStorage.getItem('authorization')
}

/**
 * 获取token
 * @returns 
 */
export const getRefreshToken = () => {
  return store.state.user.refreshToken || localStorage.getItem('refreshToken')
}


/**
 * 文字消息转为语音播放
 * @param {String} textToSpeak 要播放的文字消息
 */
export const speak = (textToSpeak: string, lang: string = 'zh-CN') => {
  return new Promise((resolve, reject) => {
    if (!window.SpeechSynthesisUtterance) {
      reject('当前浏览器不支持文字转语音服务')
    }
    window.speechSynthesis.cancel();
    const newUtterance = new SpeechSynthesisUtterance()
    newUtterance.onend = () => {
      resolve(true)
    }
    const voices = window.speechSynthesis.getVoices()
    newUtterance.text = textToSpeak.replace(/\[|\]/g, '')
    const CN = voices.filter((item) => item.lang === lang)
    const localCN = CN.filter((item) => item.localService)
    newUtterance.voice = localCN.length ? localCN[0] : CN[0]
    window.speechSynthesis.speak(newUtterance)
  })
}
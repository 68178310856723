<template>
  <el-dialog v-bind="attrs" :modelValue="true" :destroy-on-close="true" :title="title">
    <div class="ty-dialog__body">
      <div class="ty-dialog__body--video">
        <!-- <TyVideo ref="tyVideoRef" :modelValue="1" :footer="false" activeBorderColor="transparent"></TyVideo> -->
        <el-image style="width: 100%; height: 248px" :src="alarmItem.imageUrl" :preview-src-list="[alarmItem.imageUrl]"
          :preview-teleported="true" fit="contain">
          <template #error>
            <div style="display: flex; align-items: center; justify-content: center; height: 100%">
              <el-icon :size="40" color="#DCDFE6">
                <PictureFilled />
              </el-icon>
            </div>
          </template>
        </el-image>
      </div>
      <div class="ty-dialog__body--info">
        <el-row justify="space-between" style="text-align: left;line-height: 2;">
          <el-col :span="6">告警区域：</el-col>
          <el-col :span="18">{{ alarmItem.regionName }}</el-col>
          <el-col :span="6">告警设备：</el-col>
          <el-col :span="18">{{ alarmItem.deviceName }}</el-col>
          <el-col :span="6">告警等级：</el-col>
          <el-col :span="18">
            <Point :state="alarmItem.level" style="line-height: 1.5;" :options="[
    { value: 1, label: '一般告警', color: '#FFC746' },
    { value: 2, label: '重要告警', color: '#BFC3FF' },
    { value: 3, label: '紧急告警', color: '#F15151' }
  ]"></Point>
          </el-col>
          <el-col :span="6">告警类型：</el-col>
          <el-col :span="18">{{ alarmItem.typeLabel }}</el-col>
          <el-col :span="6">告警描述：</el-col>
          <el-col :span="18">{{ alarmItem.alarmDesc }}</el-col>
          <el-col :span="6">告警时间：</el-col>
          <el-col :span="18">{{ alarmItem.alarmTime }}</el-col>
          <el-col :span="6">确认状态：</el-col>
          <el-col :span="18">
            <Point :state="alarmItem.state" style="line-height: 1.5;" :options="[
    { value: 0, label: '未确认', color: '#ffffff' },
    { value: 1, label: '已确认', color: '#19c199' },
    { value: 2, label: '误报', color: '#ffc746' }
  ]"></Point>
          </el-col>
          <el-col :span="24" style="padding-top: 10px;">
            <el-button type="primary" @click.stop="confirmHandle(1)" :disabled="alarmItem.state == 1">确认</el-button>
            <el-button type="primary" @click.stop="confirmHandle(2)" :disabled="alarmItem.state == 2">误报</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <template #footer>
      <div :class="footerClass">
        <el-button v-if="showCancelButton" @click.stop="cancel('cancel')" :size="buttonSize" :type="cancelButtonType"
          :class="cancelButtonClass" :disabled="pageIndex == 1">{{ cancelButtonText }}</el-button>
        <el-button v-if="showConfirmButton" @click.stop="confirm('confirm')" :size="buttonSize"
          :type="confirmButtonType" :class="confirmButtonClass" :disabled="total && pageIndex == total">{{
    confirmButtonText }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
defineOptions({
  inheritAttrs: false
})
import { useAttrs, defineProps, ref, computed, watch, Ref, nextTick, ComputedRef } from 'vue';
import { PictureFilled } from "@element-plus/icons-vue"
import TyVideo from "@/components/TyVideo/index.vue"
import Point from '@/components/point.vue'
import type { Viewport } from "@/components/TyVideo/interface"
import { getUrl } from '@/views/video/service'

const attrs = useAttrs()
const props = withDefaults(defineProps<{
  confirm: (action: string) => boolean,
  cancel: (action: string) => boolean,
  showCancelButton?: boolean,  // 是否显示取消按钮
  cancelButtonText?: string,   // 取消按钮文本
  cancelButtonClass?: string, // 取消按钮自定义类名
  cancelButtonType?: 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'text',   // 取消按钮类型  
  showConfirmButton?: boolean, // 是否显示确定按钮
  confirmButtonText?: string,
  confirmButtonClass?: string,
  confirmButtonType?: 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'text',
  buttonSize?: 'large' | 'default' | 'small',  // 按钮尺寸
  footerClass?: string,  // 脚部样式类
  data?: Ref<any[]>,    // 告警数据
  pageIndex: Ref<number>,
  // 告警状态更新
  confirmHandle: (type: number, item: ComputedRef<any>, pageIndex: Ref<number>) => void,
}>(), {
  confirm: () => true,
  cancel: () => true,
  showCancelButton: true,
  cancelButtonText: '取消',
  cancelButtonType: 'primary',
  showConfirmButton: true,
  confirmButtonText: '确定',
  confirmButtonType: 'primary',
  buttonSize: 'default',
  footerClass: '',
  data: () => ref([]),
  pageIndex: () => ref(0),
  confirmHandle: () => { },
})

const tyVideoRef = ref<InstanceType<typeof TyVideo>>()

// 标题
const title = ref<string>()

// 总数
const total = computed(() => {
  return props.data.value.length
})
watch(() => props.data, (val) => {
  const list = val.value
  if (list?.length) {
    title.value = `${list?.[0]?.algName}(${props.pageIndex.value + 1}/${list?.length})`
  }
}, { deep: true })

// 页码
const pageIndex = computed(() => {
  return props.pageIndex.value + 1
})
watch(() => props.pageIndex, async (value) => {
  const index = value.value
  title.value = `${props.data?.value?.[index]?.algName}(${pageIndex.value}/${total.value})`
  // nextTick(async () => {
  //   const list = props.data.value
  //   if (list[index]?.subId && list[index]?.mainId) {
  //     try {
  //       const { err, url } = await asyncGetUrl(list[index])
  //       if (!err) {
  //         await tyVideoRef.value?.player(url, list[index], 0)
  //       } else {
  //         throw new Error(err)
  //       }
  //     } catch (err: any) {
  //       tyVideoRef.value?.setMsg(err.msg || err.message || err, 0)
  //     }
  //   }
  // })
}, { deep: true, immediate: true })

const alarmItem = computed(() => {
  return props.data.value?.[props.pageIndex.value] || {}
})

const asyncGetUrl = async (data: any) => {
  const res = await getUrl({
    subId: data.subId,
    mainId: data.mainId,
    isTcp: data.videoProtocol === "tcp" ? "1" : "0"
  })
  if (res.data.code == 0) {
    return { err: null, url: res.data.url }
  } else {
    return { err: res.data.msg || res.data.message, url: '' }
  }
}

const confirmHandle = async (type: number) => {
  props.confirmHandle(type, alarmItem, props.pageIndex)
}

</script>

<style lang="scss" scoped>
.ty-dialog__body {
  width: calc(100% - 40px);
  min-height: 240px;
  overflow-x: hidden;
  padding: 10px 20px 0px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 2fr;
  gap: 20px;

  >.ty-dialog__body--video,
  >.ty-dialog__body--info {
    height: 100%;
  }

  >.ty-dialog__body--info {
    display: flex;
    flex-direction: column;
  }
}
</style>
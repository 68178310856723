<template>
  <ElConfigProvider :locale="zhCN">
    <Suspense>
      <router-view></router-view>
    </Suspense>
  </ElConfigProvider>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, onMounted, reactive, toRefs, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import themes from '@/untils/theme'
// import autofit from 'autofit.js'
export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const zhCN = ref(zhCn)
    const state = reactive({
      visible: false
    })
    onMounted(() => {
      store.dispatch('mqttClient').then((res) => {
        res && store.dispatch('mqttMessage')
      })
      switchTheme()
      // autofit.init({
      //   dh: 1080,
      //   dw: 1920,
      //   el: "html",
      //   resize: true,
      //   ignore: ['#homePage', '#headerV1'],
      //   transition: 0,
      //   delay: 0
      // })
    })

    const switchTheme = () => {
      const themeObj: any = themes['darkTheme']
      document.documentElement.classList.add('skin-dark')
      Object.keys(themeObj).map((item) => {
        document.documentElement.style.setProperty(item, themeObj[item])
      })
    }
    return {
      ...toRefs(state),
      zhCN,
    }
  }
})
</script>

<style lang="scss">
@font-face {
  src: url('./assets/fonts/PingFangLight.woff2') format('truetype');

  font: {
    family: 'myFamily';
    weight: normal;
    style: normal;
  }
}

@font-face {
  font-family: ElNumber;
  font-style: normal;
  font-weight: normal;

  src: url('./assets/fonts/298-CAI978.TTF') format('truetype');
}

@font-face {
  font-family: Alibaba-PuHuiTi-Regular;
  font-style: normal;
  font-weight: normal;

  src: url('./assets/fonts/webfont.ttf') format('truetype');
}

@font-face {
  font-family: CloudZongYiGBK;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/RuiZiYunZiKuZongYiTiGBK-1.ttf') format('truetype');
}
@font-face {
  font-family: YouSheBiaoTiHei;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/YOUSHEBIAOTIHEI-2.TTF') format('truetype');
}
@font-face {
  font-family: SiYuanHeiTi;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/SOURCEHANSANSCN-NORMAL.TTF') format('truetype');
}

@font-face {
  font-family: DINNum;
  src: url('./assets/fonts/DIN Alternate Bold.ttf');
}

@font-face {
  font-family: Alibaba-PuHuiTi-Medium;
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/webfont.ttf') format('truetype');
}

#app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "SiYuanHeiTi", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5fae\8f6f\96c5\9ed1", Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;
  background-color: #11131C;
  background-size: 100% 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
}

html,
body,
* {
  padding: 0;
  margin: 0;
}

@media screen and (width >=1800px) {
  html {
    font-size: 62.5%;
  }
}

@media screen and (width >=1600px) and (width <=1800px) {
  html {
    font-size: 62.5%;
  }
}

@media screen and (width >=1400px) and (width <=1600px) {
  html {
    font-size: 55%;
  }
}

@media screen and (width >=1100px) and (width <=1400px) {
  html {
    font-size: 50%;
  }
}

@media screen and (width >=900px) and (width <=1100px) {
  html {
    font-size: 35%;
  }
}

@media screen and (width <=900px) {
  html {
    font-size: 25%;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgb(18 62 66 / 15%);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(69 162 255 / 100%);
  border-radius: 5px;
}

.el-scrollbar__bar {
  right: 1px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}

.el-scrollbar__bar.is-vertical {
  width: 5px;
}

.el-scrollbar__thumb {
  background-color: rgb(69 162 255 / 100%) !important;
}
</style>

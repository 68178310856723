<!--
 * @Descripttion: 
 * @version: 
 * @Author: huangjunjie
 * @Date: 2024-03-25 14:30:43
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-05-30 14:42:14
-->
<template>
  <div class="home-header" id="headerV1">
    <div class="header-item left">
      <div class="menu-item"
        :class="currentPath.indexOf(item.name) !== -1 || currentPath.indexOf(item.path) !== -1 ? 'active' : null"
        v-for="item in leftMenu" :key="item.path" @click="jump(item)">
        {{ item.name }}
      </div>
    </div>
    <div class="header-item center">
      <img :src="state.logo.indexLogoUrl" v-errorImg="state.logo.indexLogoUrl"
        style="width: 50px;height: 40px;vertical-align: middle;margin-right: 5px;margin-top: -5px;">
      <span>{{ state.logo.headName }}</span>
    </div>
    <div class="header-item right">
      <div class="menu-item"
        :class="currentPath.indexOf(item.name) !== -1 || currentPath.indexOf(item.path) !== -1 ? 'active' : null"
        v-for="item in rightMenu" :key="item.path" @click="jump(item)">
        {{ item.name }}
      </div>
    </div>
    <div class="time-box">
      <span class="time">{{ time }}</span>
      <el-divider direction="vertical"></el-divider>
      <span class="dddd">{{ dddd }}</span>
      <HeaderRight class="personal"></HeaderRight>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, onBeforeUnmount, ref, computed, reactive, ComputedRef } from "vue"
import moment from "moment"
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex'
import { selectSysConfig } from './service'
import { ElMessage } from 'element-plus'
import HeaderRight from './HeaderRight.vue'

const store = useStore()
const state = reactive<{ [name: string]: any }>({
  list: [],
  audioSrc: '@/assets/radio/aqd.wav',
  visible: store.state.draggle.menuVisible,
  timers: [],
  logo: {}
})

const getSysConfig = async () => {
  const res = await selectSysConfig()
  if (res.data.code == 0) {
    state.logo = res.data.data
    store.commit('setLogoConfig', state.logo)
  } else {
    ElMessage.warning('获取标题失败')
  }
}

const menu = computed(() => {
  return store.state.user.routes
})

const leftMenu = computed(() => {
  return menu.value.slice(0, 3)
})

const rightMenu = computed(() => {
  return menu.value.slice(3, 6)
})

const router = useRouter()
const route = useRoute()

const jump = (item: Record<"name" | "path", string>) => {
  const routeName = findFirstRoute(item)
  if (routeName) {
    router.push({ name: routeName })
  }
}

// 找到第一个最深层的子级路由
const findFirstRoute = (item: any) => {
  let routeName = ''
  if (item?.children?.length) {
    routeName = findFirstRoute(item.children[0])
  } else {
    routeName = item.name
  }
  return routeName
}


// 一级路由
const currentPath = computed(() => {
  return route.matched[0].name || route.matched[route.matched.length - 1].path || ''
}) as ComputedRef<string>

const days = ref<string[]>(["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"])
const time = ref<string>('')
const dddd = ref<string>('')

let timerId: number | undefined = undefined;

const getTimes = () => {
  const current = moment()
  time.value = current.format('HH:mm:ss')
  dddd.value = days.value[current.day()]
}

onMounted(async () => {
  timerId = setInterval(() => {
    getTimes()
  }, 1000)
  await getSysConfig()
})
onBeforeUnmount(() => {
  timerId && clearInterval(timerId)
})
</script>
<style lang="scss" scoped>
@mixin menu-item {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 131px));
  grid-template-rows: 33px;
  line-height: 33px;
  justify-content: space-evenly;
  align-items: center;
  align-content: end;
  gap: 10px;

  &>.menu-item {
    height: 100%;
    background: url('@/assets/ui-image/zhengning/btn.png') no-repeat center center;
    background-size: 100% 100%;
    font-size: 22px;
    font-family: 'YouSheBiaoTiHei';
    // font-weight: bold;
    // font-style: italic;
    text-align: center;
    // text-shadow: 0 0 4px var(--el-color-primary);
    margin-bottom: 18px;

    &.active,
    &:hover {
      cursor: pointer;
      background: url('@/assets/ui-image/zhengning/btn_active.png') no-repeat center center;
      background-size: 100% 100%;
    }
  }
}


.home-header {
  width: 100%;
  height: 88%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: center;
  color: #ffffff;
  position: relative;
  background: url("@/assets/ui-image/zhengning/header_bg.png") no-repeat center top;
  background-size: 86% 86%;

  &>.time-box {
    position: absolute;
    top: 5px;
    right: 30px;

    .time {
      font-size: 22px;
      line-height: 28px;
      font-family: 'Helvetica Neue';
    }

    .dddd {
      font-size: 16px;
      line-height: 16px;
      margin-right: 15px;
      font-family: SiYuanHeiTi;
    }

    .personal {
      line-height: 20px !important;
      align-self: end;
    }
  }

  >.header-item {
    &.center {
      text-align: center;
      line-height: 42px;
      margin-top: -10px;
      display: flex;
      justify-content: center;
      align-items: center;

      >img {
        display: block;
      }

      >span {
        letter-spacing: 12px;
        font-size: 42px;
        font-family: 'CloudZongYiGBK';
        text-shadow: 0 0 20px var(--el-color-primary);
      }
    }

    &.left,
    &.right {
      @include menu-item();
    }
  }
}
</style>
/*
 * @Descripttion:
 * @version:
 * @Author: huangjunjie
 * @Date: 2024-04-29 10:29:34
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-04-29 14:38:32
 */
import moment from 'moment'
import { store } from '@/store/index'
import { computed } from 'vue'

//自定义日期处理
export default (app: any) => {
  app.directive('date', (el: any, val: { arg: string; value: string }) => {
    el.innerHTML = moment(val.value).format(val.arg)
  })
  app.directive('errorImg', (el: any, val: { value: string }) => {
    if (val.value) {
      let img = new Image()
      img.src = val.value
      img.onload = () => {
        el.style.display = 'inline-block'
      }
      img.onerror = () => {
        el.style.display = 'none'
      }
    } else {
      el.style.display = 'none'
    }
  })
  app.directive('hasPermi', {
    mounted(el: any, binding: { value: any }, vnode: any) {
      const { value } = binding
      const all_permission = '*:*:*'
      if (value) {
        const permissionFlag = value
        let hasPermissions = false
        const permissions = store.state.user.permissions
        if (value instanceof Array && value.length > 0) {
          hasPermissions = permissions.some((permission: string) => {
            return all_permission === permission || permissionFlag.includes(permission)
          })
        } else if (typeof value == 'string') {
          hasPermissions = permissions.some((permission: string) => {
            return all_permission === permission || permission === permissionFlag
          })
        }
        if (!hasPermissions) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        // throw new Error(`请设置操作权限标签值`)
      }
    }
  })
}

const RegionTreeModel = {
  state: () => ({
    deviceChange: {
      del: null,
      change: null
    },
    deviceList: []
  }),
  mutations: {
    setDeviceChange(state: any, data: any) {
      state.deviceChange = data
    },
    setDeviceList(state: any, data: any) {
      state.deviceList = data
    }
  }
}

export default RegionTreeModel

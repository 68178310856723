// import * as mqtt from 'mqtt/dist/mqtt.min'

let Client: any
const mqttConnect: (url: string, theme: string[], option?: { userName: string; password: string }) => any = (url: string, theme: string[], option?: { userName: string; password: string }) => {
  const SERVICE = url
  Client = new (window as any).Paho.MQTT.Client(SERVICE, `xby${Math.round(Math.random() * 800 + 100)}`)
  Client.connect({
    ...option,
    keepAliveInterval: 30,
    timeout: 3,
    onSuccess: () => {
      for (let i = 0; i < theme.length; i++) {
        Client.subscribe(theme[i], {
          qos: 2
        })
      }
    },
    onFailure: (error: any) => {
      console.log('断开重连', error)
    }
  })

  return Promise.resolve(true)
}

export { Client, mqttConnect }

<template>
  <div class="secondary_nav">
    <el-space :size="20">
      <router-link exact v-for="(pane, idx) in breadList" :to="{ name: pane.name }" :key="idx">{{ pane?.meta?.title }}</router-link>
    </el-space>
  </div>
</template>

<script lang="ts" setup>
export interface OptionType {
  id: string
  name: string
}
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
const route = useRoute()
const router = useRouter()

const breadList = computed(() => {
  // @ts-ignore
  return route.matched[0]['children']?.filter(d => d.hidden === false)
})
</script>

<style scoped lang="scss">
.tyTabs {
  width: 100%;
  height: 30px !important;

  :deep(.el-tabs__header) {
    border-bottom: 1px solid #0085ff;
  }

  :deep(.el-tabs__item) {
    color: #707d94 !important;
    font-weight: bold;
  }

  :deep(.is-active) {
    color: #0085ff !important;
    font-weight: bold;
  }

  :deep(.el-tabs__nav-wrap::after) {
    background-color: #1b3161 !important;
  }

  .el-tabs__content {
    height: 0 !important;
  }
}

.secondary_nav {
  border-bottom: 1px solid #1b3161;
  a {
    text-decoration: none;
    color: #707d94;
    font-weight: bold;
    padding: 10px 0;
  }
  .router-link-active {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    color: #0085ff;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: -5%;
      height: 2px;
      width: 110%;
      background-color: #0085ff;
    }
  }
}
</style>

import TyDialog from './index.vue'
import { createApp } from 'vue'

TyDialog.createDialog = async (
  props: {
    confirmCallback?: (action: string) => any
    cancelCallback?: (action: string) => any
    beforeClose?: (done: () => any, action: string) => boolean
    [key: string | symbol]: any
  } = {
    confirmCallback: () => true,
    cancelCallback: () => true,
    beforeClose: () => true
  }
) => {
  const containerBox = document.createElement('div')
  document.body.appendChild(containerBox)

  const destory = () => {
    tyDialogApp.unmount()
    containerBox.remove()
  }

  const confirm = async (action: string) => {
    props?.confirmCallback?.(action)
    if (props?.beforeClose) {
      await props?.beforeClose?.(destory, action)
    } else {
      destory()
    }
  }
  const cancel = async (action: string) => {
    props?.cancelCallback?.(action)
    if (props?.beforeClose) {
      await props?.beforeClose?.(destory, action)
    } else {
      destory()
    }
  }
  props.confirm = confirm
  props.cancel = cancel

  const tyDialogApp = createApp(TyDialog, props)
  tyDialogApp.mount(containerBox)
  // @ts-ignore
  tyDialogApp.diyDestory = destory
  return tyDialogApp
}

export { TyDialog }
export default TyDialog

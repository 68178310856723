import axios from 'axios'
import router from '@/router'
import { store } from '@/store/index'
import { apiGetRefreshToken } from '@/views/admin/service'
import { computed } from 'vue'
import { handleJumpToLogin, isPlatformtoken, getToken, getRefreshToken } from '@/untils'

const env: any = import.meta.env.MODE || 'development'

import { ElMessage, ElNotification } from 'element-plus'
// 服务的网关环境类
const SERVERS: Record<string, (env: string, request: { url: string }) => string> = {
  cpi: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  ins: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  base: function (env, request) {
    if (env == 'development') {
      return request.url.replace(/^\/base/, '/base/baseService-server')
    }
    return request.url.replace(/^\/base/, '/base/baseService-server')
  },
  perms: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  file: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  '3d': function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  a: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  },
  resource: function (env, request) {
    return env == 'development' ? '' + request.url : '' + request.url
  }
}

const handle401 = (response: any) => {
  // 如果refreshToken也过期则需要重新登录
  ElNotification({
    title: `登录过期，请重新登录`,
    message: `${response.config.url}`,
    duration: 2000,
    type: 'error'
  })
  setTimeout(() => {
    localStorage.removeItem('authorization')
    localStorage.removeItem('refreshToken')
    handleJumpToLogin(isPlatformtoken.value)
  }, 500)
}

axios.interceptors.request.use((request: any) => {
  const path = request.url.split('?')
  request.headers.Authorization = getToken() || ''
  const TYPE = path[0].split('/')[1]
  request.url = SERVERS[TYPE] ? SERVERS[TYPE](env, request) : env == 'development' ? '/base/baseService-server' + request.url : '/base/baseService-server' + request.url
  // 开发阶段跨域域名
  return request
})
// 响应拦截
axios.interceptors.response.use(
  async (response: any) => {
    if (!response.data.type) {
      const httpCode = response.data.code
      // 处理业务状态码报错
      if (httpCode !== 0 && httpCode !== 200) {
        // token过期,尝试refreshToken获取新token
        if (httpCode == 503 && !response.config.isRefreshToken) {
          const refreshToken = getRefreshToken()
          if (isPlatformtoken.value && refreshToken) {
            const res = await apiGetRefreshToken(
              {
                refreshToken: refreshToken
              },
              {
                isRefreshToken: true,
                noMessage: true
              }
            )
            // 获取到新token后重新发送请求
            console.log('刷新token并重新发送请求', res)
            if (res.data.code == 0 || res.data.code == 200) {
              const newToken = res.data?.data?.authorization
              response.config.headers.Authorization = newToken
              response.config.isRefreshToken = true
              // 重发请求时敲除 baseService-server 代理
              response.config.url = response.config?.url?.replace(/\/baseService-server/, '')
              axios.request(response.config).then((res: any) => {
                // 成功代表token有效，保存token
                console.log('重试请求token有效', res)
                store.commit('SET_TOKEN', newToken)
                localStorage.setItem('authorization', newToken)
              })
            } else {
              // 如果refreshToken也过期则需要重新登录
              handle401(response)
            }
          } else {
            handle401(response)
          }
        }
        if(httpCode == 1308) {
          handle401(response)
          return response
        }
        // token
        if (response.config.noMessage || httpCode == 503) {
          // 不提示错误信息
          return response
        }
        ElMessage.error(response.data.message || response.data.msg)
      }
    }
    return response
  },
  (error) => {
    if (error.response.status == 500) {
      ElNotification({
        title: '服务器错误',
        message: error.response.config.url,
        duration: 2000,
        type: 'error'
      })
    } else if (error.response.status == 401) {
      const httpCode = error.response.data.code
      if (httpCode == 1307) {
        ElNotification({
          title: `登录过期，请重新登录`,
          message: `${error.response.config.url}`,
          duration: 2000,
          type: 'error'
        })
        //toklen过期
        router.push('/login')
      } else if (httpCode == 1309) {
        ElNotification({
          title: `无权限访问`,
          message: `${error.response.config.url}`,
          duration: 2000,
          type: 'error'
        })
      }
    } else if (error.response.status == 404) {
      ElNotification({
        title: `路径请求错误${error.response.status}`,
        message: error.response.config.url,
        duration: 2000,
        type: 'error'
      })
    } else {
      ElNotification({
        title: '请求错误${error.response.status}',
        message: error.response.config.url,
        duration: 2000,
        type: 'error'
      })
    }
  }
)

export default axios

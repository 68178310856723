const PollingModel = {
  state: () => ({
    activeCameras: [], //当前巡视的摄像机信息
    cameraIndex: 4, //当前窗口数量
    index: 0, //当前激活窗口
    videoObj: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: ''
    },
    activeVideo: {
      0: '',
      1: '',
      2: '',
      3: '',
      4: ''
    },
    activeStation: null,  // 当前选中站点
  }),
  mutations: {
    setActiveStation(state: any, data: any) {
      state.activeStation = data
    },
    setAcitveCamera(state: any, data: any) {
      state.activeCameras = data
    },
    setCameraIndex(state: any, data: any) {
      state.cameraIndex = data
    },
    //
    setIndex(state: any, data: number) {
      if (data > 0 && data < state.cameraIndex) {
        state.index = data
      } else {
        state.index = 0
      }
    },
    setVideoObj(state: any, data: any) {
      state.videoObj = data
    },
    setActiveVideo(state: any, data: any) {
      state.activeVideo[state.index] = data
    }
  }
}
export default PollingModel

<template>
  <div class="my_main">
    <div class="my_card" :style="cardBgStyle(header.position)">
      <div class="my_header" v-if="header.title">
        <div>
          <div class="header_content">
            <div class="header_lft">
              <!--                 <i :class="[header.icon]"></i>-->
              <!--                 <img src="../assets/ui-image/-s-标题箭头.png" alt />-->
              <p>
                <!--                                  <i :class="[header.icon]"></i>-->
                {{ header.title }}
              </p>
            </div>
            <slot name="header">
              <div v-if="header.rightIcon" class="right-icon">
                <p type="text" @click="$emit('more')">
                  <i :class="header.rightIcon"></i>
                </p>
              </div>
              <div v-if="header.rightIcon2" class="right-icon">
                <p type="text" @click="$emit('more')">
                  <i :class="header.rightIcon2"></i>
                </p>
              </div>
              <div v-if="header.rightText || header.rightText2" class="header_rgt">
                <p v-if="header.rightText" type="text" @click="$emit('more', header.rightText)" :class="header.tabName === header.rightText ? 'active' : ''">
                  <i :class="header.rightIcon"></i>
                  {{ header.rightText }}
                </p>
                <p v-if="header.rightText2 || header.rightIcon2" type="text" @click="$emit('more', header.rightText2)" :class="header.tabName === header.rightText2 ? 'active' : ''">
                  <i :class="header.rightIcon2"></i>
                  {{ header.rightText2 }}
                </p>
              </div>
              <img style="width: 16px; height: 16px; margin-right: 10px; cursor: pointer" @click="$router.back()" v-if="header.back" src="../assets/ui-image/icon_back.svg" alt />
            </slot>
          </div>
        </div>
      </div>
      <div class="body" :style="boyStyle(header.title)">
        <el-scrollbar ref="scroll" v-if="isScroll == true" style="box-sizing: border-box; height: 100%; padding: 10px;">
          <slot name="body"></slot>
        </el-scrollbar>
        <div v-else style="box-sizing: border-box; height: 100%; padding: 10px; overflow: hidden">
          <slot name="body"></slot>
        </div>
        <div class="angle1"></div>
        <div class="angle2"></div>
        <div class="angle3"></div>
        <div class="angle4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { transform } from 'lodash'

export default defineComponent({
  props: {
    header: {
      type: Object,
      default: () => ({
        title: '', //标题
        icon: '', //图标
        rightText: '',
        rightText2: '',
        rightIcon: '',
        rightIcon2: '',
        // 位置
        position: '',
        // tab 名字
        tabName: '',
        back: false
      })
    },
    isScroll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['turnTo'],
  methods: {
    boyStyle(title) {
      if (!title || title == '') {
        return {
          height: 'calc(100% )'
        }
      } else
        return {
          height: 'calc(100% - 40px)'
        }
    },
    cardBgStyle(position) {
      if (!position) {
        return ''
      } else if (position === 'home') {
        return {
          background: 'transparent'
        }
      }
    }
  }
})
</script>
<style lang="scss" scoped>
$bg_header: transparent;

// $bg_body: rgb(0 117 212 / 5%);
$bg_body: #141b28;
$color: #0085ff; //#ddefff
$active_color: #0082ff;
$border: 1px solid rgba(27, 49, 97, 1);
.my_main {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: $border;
  background-color: $bg_body;

  // background-color: $bg_header;
}
.my_card {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  // background-color: $bg_header;
  // background-color: $bg_body;
  // border: $border;
  .my_header {
    // background: transparent;
    overflow: hidden;
    // background: $bg_header;
    .header_content {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      overflow: hidden;
      // background-color: #141b28;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        border-bottom: 1px solid;
        border-image: linear-gradient(270deg, rgba(27, 49, 97, 0.4) 0%, #1b3161 100%) 1 1 1 1;
        // height:1px;
        // background:#1b3161;
      }
      // border: $border;

      // background: linear-gradient(to right, rgba(3, 58, 111, 1), rgba(3, 58, 111, 0.3));
      // border-image: linear-gradient(to right, rgba(0, 133, 255, 1), rgba(0, 133, 255, 0));
      // border-bottom: 1px solid;
      // border-radius: 2px;
      // border-image: linear-gradient(90deg, #1B3161 0%, rgb(0 133 255 / 0%) 100%) 1 1;
    }
    .header_lft {
      display: flex;
      flex: 1;
      align-items: center;
      height: 40px;
      // margin-left: 10px;
      line-height: 40px;
      text-align: left;

      // border-left: 5px solid $active_color;
      // background: url('../assets/ui-image/-s-标题箭头.png') no-repeat;

      // background-position-x: -16px;
      // background-position-y: 50%;
      img {
        margin-right: 11px;
        vertical-align: middle;
      }
      p {
        // font-family: "Alibaba-PuHuiTi-Medium";
        display: inline-block;
        height: 30px;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
        color: $color;
        font-weight: bold;
        vertical-align: middle;

        //background: url("../assets/ui-image/title_bg.png") no-repeat center center;
        background-size: 100% 100%;
        i {
          padding-right: 2px;
          font-size: 14px;
          color: $active_color;
          vertical-align: middle;
        }
      }
    }
    .header_rgt {
      box-sizing: border-box;
      display: flex;
      flex: 0 0 260px;
      padding-right: 10px;
      align-items: center;
      justify-content: flex-end;
      height: 30px;
      color: $active_color;
      color: #0099ff;

      // text-shadow: 0px 0px 7px $color;
      text-align: right;
      p {
        box-sizing: border-box;
        height: 26px;
        padding: 0 15px;
        font-family: 'PingFang SC', 'PingFang SC-Medium', serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #4097e9;
        text-align: center;
        background: linear-gradient(180deg, rgb(0 133 255 / 11%), rgb(0 133 255 / 53%) 100%);
        border: 1px solid;
        border-radius: 1px;
        border-image: linear-gradient(264deg, #016dd0 0%, #00c2ff 0%, #016dd0 100%) 1 1;
        box-shadow: 0 0 1px 0 #0085ff;
        i {
          font-size: 18px;
        }
      }
      p:hover {
        color: #ffffff;
        cursor: pointer;
        background: linear-gradient(180deg, rgb(12 139 255 / 27%), rgb(0 133 255 / 71%) 100%, rgb(0 133 255 / 72%) 100%);
        border: 1px solid;
        border-radius: 1px;
        border-image: linear-gradient(264deg, #016dd0 0%, #00c2ff 0%, #016dd0 100%) 1 1;
        box-shadow: 0 0 1px 0 #0085ff;
      }
      .active {
        color: #ffffff;
        background: linear-gradient(180deg, rgb(12 139 255 / 27%), rgb(0 133 255 / 71%) 100%, rgb(0 133 255 / 72%) 100%);
        border: 1px solid;
        border-radius: 1px;
        border-image: linear-gradient(264deg, #016dd0 0%, #00c2ff 0%, #016dd0 100%) 1 1;
        box-shadow: 0 0 1px 0 #0085ff;
      }
    }
  }
  .body {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    // border: $border;
  }
  .right-icon {
    margin-right: 10px;
    p {
      color: #0082ff;
    }
    p:hover {
      color: #ffffff;
      cursor: pointer;
    }
  }

  //.angle1 {
  //  position: absolute;
  //  height: 16px;
  //  width: 16px;
  //  left: 1px;
  //  top: 1px;
  //  border-top: 2px solid rgba(69, 162, 255, 0.8) !important;
  //  border-left: 2px solid rgba(69, 162, 255, 0.8) !important;
  //}
  //.angle2 {
  //  position: absolute;
  //  height: 16px;
  //  width: 16px;
  //  left: 1px;
  //  bottom: 1px;
  //  border-bottom: 2px solid rgba(69, 162, 255, 0.8) !important;
  //  border-left: 2px solid rgba(69, 162, 255, 0.8) !important;
  //}
  //.angle3 {
  //  position: absolute;
  //  height: 16px;
  //  width: 16px;
  //  right: 1px;
  //  top: 1px;
  //  border-top: 2px solid rgba(69, 162, 255, 0.8) !important;
  //  border-right: 2px solid rgba(69, 162, 255, 0.8) !important;
  //}
  //.angle4 {
  //  position: absolute;
  //  height: 16px;
  //  width: 16px;
  //  right: 1px;
  //  bottom: 1px;
  //  border-bottom: 2px solid rgba(69, 162, 255, 0.8) !important;
  //  border-right: 2px solid rgba(69, 162, 255, 0.8) !important;
  //}
}
</style>

import { speak as diySpeak } from '@/untils/index'

/**
 * 语音播报
 */
export const useSpeak = () => {
  let timerIds: Array<number> = []
  const speak = async (text: string, time: number, playInterval: number) => {
    await diySpeak(text)
    time = time - 1
    if (time >= 1) {
      timerIds.push(
        setTimeout(async () => {
          await speak(text, time, playInterval)
        }, playInterval)
      )
    } else {
      clearSpeak()
    }
  }

  const clearSpeak = () => {
    window.speechSynthesis?.cancel()
    timerIds.forEach((d) => {
      clearTimeout(d)
    })
  }

  return {
    speak,
    clearSpeak
  }
}

/**
 * 音频播报
 */
export const usePlay = () => {
  let timerIds: Array<number> = []
  let audio: HTMLAudioElement | null = document.createElement('audio')

  const play = async (fileUrl: string, time: number, playInterval: number) => {
    await diyPlay(fileUrl)
    time = time - 1
    if (time >= 1) {
      timerIds.push(
        setTimeout(async () => {
          await play(fileUrl, time, playInterval)
        }, playInterval)
      )
    } else {
      clearPlay()
    }
  }

  const diyPlay = (voiceFile: string) => {
    return new Promise((resolve, reject) => {
      if (audio) {
        audio.addEventListener('ended', () => {
          resolve(true)
        })
        audio.addEventListener('error', () => {
          resolve(false)
        })
        audio.src = voiceFile
        audio.play()
      }
    })
  }

  const clearPlay = () => {
    audio?.remove()
    audio = null
    timerIds.forEach((d) => {
      clearTimeout(d)
    })
  }

  return {
    play,
    clearPlay
  }
}

<template>
  <div style="display: inline-flex;align-items: center;">
    <!-- <div class="img_content" ref="infoRef">
      <div class="img_content_badge">{{ count > 99 ? '99+' : count }}</div>
      <el-popover :virtual-ref="infoRef" :width="200" trigger="hover" effect="dark" virtual-triggering
        placement="bottom-end">
        <div>
          <div class="message_item">
            <div class="message_item_info">
              告警消息
            </div>
          </div>
          <div class="message_item" v-for="(item, index) in messageList" :key="index">
            <div class="message_item_info">{{ item.alarmDesc }}</div>
            <div class="message_item_time">{{ item.alarmTime }}</div>
          </div>
          <div class="bottom-box">
            <div class="bottom-box-left">
              告警弹窗
              <el-switch v-model="globalVoiceSwitch" active-value="1" inactive-value="0"></el-switch>
            </div>
            <div v-hasPermi="['alarmManager:alert:list']" class="message_ftr" @click="menuClick('more')">
              查看更多
              <el-icon>
                <ArrowRight />
              </el-icon>
            </div>
          </div>
        </div>
      </el-popover>
    </div> -->
    <div style="display: inline-flex;cursor: pointer;justify-content: center;align-items: center;align-content: end;"
      ref="buttonRef" :size="3">
      <el-avatar :size="18" :src="userImg" />
      <span class="user_name">{{ userInfo?.nickName }}</span>
      <el-icon>
        <ArrowDown />
      </el-icon>
    </div>
    <el-popover ref="popoverRef" :virtual-ref="buttonRef" trigger="hover" effect="dark" virtual-triggering
      placement="bottom-end">
      <div v-if="!isPlatformtoken" class="admin-item" @click="menuClick('edit')">
        <el-space>
          <el-icon>
            <EditPen />
          </el-icon>
          修改密码
        </el-space>
      </div>
      <div class="admin-item" @click="menuClick('loginout')">
        <el-space>
          <el-icon>
            <SoldOut />
          </el-icon>
          退出登录
        </el-space>
      </div>
    </el-popover>
  </div>

  <!-- 修改密码弹窗 -->
  <el-dialog v-model="dialogVisible" title="修改密码" width="500" :before-close="beforeClose">
    <div style="padding: 20px 20px 0;">
      <el-form ref="formRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="旧密码：" prop="oldPassword">
          <el-input v-model="form.oldPassword" type="password" />
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input v-model="form.newPassword" type="password" />
        </el-form-item>
        <el-form-item label="确认密码：" prop="reenteredPassword">
          <el-input v-model="form.reenteredPassword" type="password" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div>
        <el-button @click="cancelHandle">取消</el-button>
        <el-button type="primary" @click="submitHandle">
          提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, nextTick, watch, reactive, onBeforeUnmount } from 'vue'
import type { Ref, ComputedRef } from "vue"
import userImg from '@/assets/ui-image/1home/user.svg'
import { useStore } from 'vuex'
import { SoldOut, Key, ArrowDown, ArrowRight, Setting, EditPen } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { selectPageList, algAlarmConfirmState } from '@/views/alert/service'
import { apiUpdatePwd, apiGetAlarmType, apiSaveSysConfig, apiGetAlarmAll } from "./service"
import type { FormRules } from "element-plus"
import { ElForm, ElMessage, ElNotification } from "element-plus"
import { handleJumpToLogin, isPlatformtoken } from '@/untils/index'
import { TyDialog } from "@/components/TyDialog/index"
import { useSpeak, usePlay } from './useSpeak'

const buttonRef = ref(null)
const infoRef = ref(null)

const store = useStore()
const userInfo = computed(() => store.state.user.userInfo)

// 告警消息
// 告警消息
// 告警消息
const messageList = ref<{ alarmDesc: string; alarmTime: string }[]>([])
const count = ref(0)
const getAlarmList = async () => {
  //获取告警信息未确认前5条
  const res = await selectPageList({ pageIndex: 1, pageSize: 5, state: 0 })
  if (res.data.code == 0) {
    messageList.value = res.data.data
    count.value = res.data.count
  }
}


// 获取告警类型
// 获取告警类型
// 获取告警类型
const alarmTypeList = ref<Record<'alarmTypeName' | 'id', string>[]>([])
async function getAlarmTypes() {
  const res = await apiGetAlarmType()
  if (res.data.code == 0) {
    alarmTypeList.value = res.data.data
  }
}

onMounted(async () => {
  await getAlarmTypes()
  await getAlarmList()
  document.addEventListener('click', clickDialogTopHandle)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', clickDialogTopHandle)
})

const globalVoiceSwitch = computed({
  get: () => store.state.user.logoConfig.globalVoiceSwitch,
  set: async (val) => {
    const newConfig = {
      ...store.state.user.logoConfig,
      globalVoiceSwitch: val
    }
    const res = await apiSaveSysConfig(newConfig)
    if (res.data.code == 0) {
      store.commit('setLogoConfig', newConfig)
    }
  }
})

// 告警消息推送
const alarmCenterMap: Record<string, Ref<any[]> | undefined> = {}
const alarmIndexMap: Record<string, Ref<number> | undefined> = {}
let singleSpeak: any = null    // 单例播报
let singlePlay: any = null    // 单例播报
watch(
  () => store.state.mqtt.alarmCenter,
  async (val) => {
    if (val.state == 0) {
      // 队列操作
      messageList.value.unshift(val)
      messageList.value.pop()
      count.value = count.value + 1
      if (globalVoiceSwitch.value == '1') {
        const alarmVoiceDto = val.alarmVoiceDto
        // 只有巡检类告警才需要判断专业
        if (alarmVoiceDto?.alarmCategory == '1' && !val?.subjectList?.some((d: any) => d.id == userInfo.value.subjectId)) {
          return
        }
        let numberPlays: number = alarmVoiceDto.numberPlays || 1
        const playInterval = (alarmVoiceDto.playInterval || 5) * 1000
        if (alarmVoiceDto?.voiceSwitch == '1') {
          // 处理声音播放
          // 处理声音播放
          // 处理声音播放
          singlePlay?.clearPlay?.()
          singleSpeak?.clearSpeak?.()
          if (alarmVoiceDto?.alarmVoiceType == 2 && alarmVoiceDto.voiceFile) {
            singlePlay = usePlay()
            singlePlay.play(alarmVoiceDto.voiceFile, numberPlays, playInterval)
          } else {
            singleSpeak = useSpeak()
            singleSpeak.speak(`${val.regionName}区域出现${val.algName}告警，请尽快处理！`, numberPlays, playInterval)
          }
          // 处理弹窗：没有该算法告警缓存则创建一个弹窗
          // 处理弹窗：没有该算法告警缓存则创建一个弹窗
          // 处理弹窗：没有该算法告警缓存则创建一个弹窗
          if (val.algName && !alarmCenterMap[val.algName] && !alarmCenterMap[val.algName]) {
            alarmCenterMap[val.algName] = ref<any[]>([])
            alarmIndexMap[val.algName] = ref<number>(0)
            // 查询所有未处理告警
            const res = await apiGetAlarmAll({ state: '0', algName: val.algName })
            const unHandleAlarmList = res.data?.data?.alarmCenterList || []
            if (unHandleAlarmList.length) {
              alarmCenterMap[val.algName]!.value = alarmCenterMap[val.algName]!.value.concat(unHandleAlarmList.map((d: any) => ({
                ...d,
                typeLabel: alarmTypeList.value.find(d => d.id == val.type)?.alarmTypeName || val.type
              })))
            }
            const tyDialogInstance = await TyDialog.createDialog({
              modal: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              draggable: true,
              width: '800px',
              title: `${val.algName}告警`,
              confirmButtonText: '下一页',
              cancelButtonText: '上一页',
              zIndex: 9999,
              data: alarmCenterMap[val.algName],
              pageIndex: alarmIndexMap[val.algName],
              id: 'tyDialogUniqueIdButPossibleRepeat',
              confirmCallback: () => {
                alarmIndexMap[val.algName]!.value = alarmIndexMap[val.algName]!.value + 1
              },
              cancelCallback: () => {
                alarmIndexMap[val.algName]!.value = alarmIndexMap[val.algName]!.value - 1
              },
              beforeClose: (done: Function, action: string) => {
                if (!action) {
                  alarmCenterMap[val.algName] = undefined
                  alarmIndexMap[val.algName] = undefined
                  tyDialogInstance.diyDestory() // done
                  singleSpeak?.clearSpeak?.()
                  singleSpeak = null
                  singlePlay?.clearPlay?.()
                  singlePlay = null
                }
              },
              confirmHandle: async (type: number, currentItem: ComputedRef<any>, currentIndex: Ref<number>) => {
                const res = await algAlarmConfirmState({ ids: [currentItem.value.id], state: type })
                ElNotification({
                  title: '状态更新',
                  message: res.data.message,
                  duration: 2000,
                  type: res.data.code == 0 ? 'success' : 'error'
                })
                if (res.data.code == 0) {
                  const alarmList = alarmCenterMap[currentItem.value.algName]?.value
                  if (alarmList) {
                    alarmList.splice(currentIndex.value, 1)
                    if (!alarmList.length) {
                      alarmCenterMap[currentItem.value.algName] = undefined
                      alarmIndexMap[currentItem.value.algName] = undefined
                      tyDialogInstance.diyDestory() // done
                      singleSpeak?.clearSpeak?.()
                      singleSpeak = null
                      singlePlay?.clearPlay?.()
                      singlePlay = null
                      return
                    }
                    if (currentIndex.value > alarmList.length - 1) {
                      currentIndex.value = alarmList.length - 1
                    }
                  }
                }
              }
            })
          } else {
            val.typeLabel = alarmTypeList.value.find(d => d.id == val.type)?.alarmTypeName || val.type
            alarmCenterMap[val.algName]?.value.unshift(val)
            alarmIndexMap[val.algName]!.value = 0
          }
        }
      }
    }
  }
)

// 点击弹窗置顶
const clickDialogTopHandle = (event: MouseEvent) => {
  const topElement = document.elementFromPoint(event.x, event.y)
  if (topElement?.classList.contains('el-image-viewer__img') || topElement?.classList.contains('el-image-viewer__mask')) {
    return
  }
  const elDialogElements = document.querySelectorAll('#tyDialogUniqueIdButPossibleRepeat')
  if (elDialogElements.length > 1) {
    const elements = document.elementsFromPoint(event.x, event.y)
    const targetElements = elements.filter(d => {
      return /tyDialogUniqueIdButPossibleRepeat/.test(d.id)
    })
    if (targetElements.length !== 0) {
      elDialogElements.forEach(d => {
        const parentElement = d?.parentElement?.parentElement
        if (parentElement) {
          parentElement.style.zIndex = '3000'
        }
      })
      const targetParentElement = targetElements[0]?.parentElement?.parentElement
      if (targetParentElement) {
        targetParentElement.style.zIndex = '9998'
      }
    }
  }
}

// 跳转
// 跳转
// 跳转
const router = useRouter()
const menuClick = async (type: 'loginout' | 'config' | 'more' | 'edit') => {
  if (type == 'loginout') {
    store.dispatch('loginout').then(res => {
      localStorage.removeItem('authorization')
      localStorage.removeItem('refreshToken')
      handleJumpToLogin(isPlatformtoken.value)
    })
  } else if (type == 'more') {
    router.push({ path: '/alarm/alarmManager', query: { state: 0 } })
  } else if (type == 'config') {
    router.push({ path: '/system' })
  } else if (type == 'edit') {
    dialogVisible.value = true
  }
}

// 修改密码
// 修改密码
// 修改密码
const formRef = ref<InstanceType<typeof ElForm>>()
const dialogVisible = ref(false)
const form = ref({
  oldPassword: '',
  newPassword: '',
  reenteredPassword: '',
})
const beforeClose = async (done: any) => {
  await formRef.value?.resetFields()
  done()
}
const cancelHandle = () => {
  dialogVisible.value = false
  formRef.value?.resetFields()
}
const submitHandle = async () => {
  const valid = await formRef.value?.validate()
  const res = await apiUpdatePwd(form.value)
  if (res.data.code == 0) {
    ElMessage.success('修改成功，请重新登录!')
    dialogVisible.value = false
    menuClick('loginout')
  }
}
const rules = reactive<FormRules<typeof form.value>>({
  oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'change' }],
  newPassword: [
    { required: true, message: '请输入新密码', trigger: 'change' },
    {
      validator: (rule: any, value: any, callback: any) => {
        const containSpecial = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9]{8,20}$/)
        if (!containSpecial.test(value)) {
          callback(new Error('密码需包含大写字母、小写字母、数字，长度不小于8位，不大于20位'))
        } else {
          callback()
        }
      },
      trigger: 'change'
    },
  ],
  reenteredPassword: [
    { required: true, message: '请确认密码', trigger: 'change' },
    {
      validator: (rule: any, value: any, callback: any) => {
        if (form.value.newPassword && form.value.newPassword !== value) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }
      },
      trigger: 'change'
    },
  ],
})
</script>

<style lang="scss" scoped>
.admin-item {
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  font-size: 14px;

  &:hover {
    background-color: #22618f;
  }
}

.img_content {
  display: inline-block;
  cursor: pointer;
  z-index: 999;
  height: 20px;
  width: 20px;
  background-image: url('@/assets/ui-image/1home/message.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 15px;

  .img_content_badge {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;
    background-color: #ff5a24;
    border-radius: 50%;
    font-size: 0.8em;
    line-height: 15px;
  }
}

.el-avatar {
  background-color: transparent !important;
}

.user_name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message_item {
  color: #fff;
  padding: 5px 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #32415a 20%, rgba(255, 255, 255, 0) 99%) 2 2 2 2;

  &:first-child {
    .message_item_info {
      font-weight: bolder;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .message_item_info {
    font-size: 12px;
    padding-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .message_item_time {
    font-size: 12px;
    color: #707d94;
  }
}

.bottom-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bottom-box-left {
    color: #fff;
  }

  .message_ftr {
    font-size: 12px;
    color: #0085ff;
    // padding-top: 15px;
    text-align: right;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}
</style>

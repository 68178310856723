<template>
  <span class="point-content">
    <span class="point"></span>
    {{ stateFunc(state) }}
  </span>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
const props = defineProps({
  state: { required: true, default: 0 },
  options: { required: true, default: [{ color: '', label: '', value: 0 }] }
})
const colorValue = ref('')
const borderValue = ref('')
const contentBackgroundColor = ref('')
const stateFunc = (state: number) => {
  const filterData = props.options.find((item) => item.value == state)
  return filterData ? filterData.label : state
}
watch(
  () => props,
  (val) => {
    const filterData = props.options.find((item) => item.value == val.state)
    colorValue.value = filterData ? filterData.color : ''
    borderValue.value = filterData ? filterData.color + 30 : ''
    contentBackgroundColor.value = filterData ? filterData.color + 15 : ''
  },
  { deep: true, immediate: true }
)
</script>

<style scoped lang="scss">
$color: v-bind(colorValue);
$borderValue: v-bind(borderValue);
$contentBackgroundColor: v-bind(contentBackgroundColor);
.point-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
  color: $color;
  text-align: center;
  background-color: $contentBackgroundColor;
  border: 1px solid $borderValue;
  border-radius: 2px;
  .point {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 5px;
    background-color: $color;
    border-radius: 50%;
  }
}
</style>

/*
 * @Descripttion:
 * @version:
 * @Author: huangjunjie
 * @Date: 2024-04-01 14:23:52
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-04-23 15:13:14
 */
import axios from 'axios'

export const login = (data: { username: string; password: string }) => {
  return axios.post('/perms/login', data)
}

// 刷新token
export const apiGetRefreshToken = (data: {
  authorization?: string,
  refreshToken?: string,
}, config: any = {}) => {
  return axios.post('/perms/acquireToken', data, config)
}

export const getUser = () => {
  return axios.get('/perms/getInfo')
}
export const logout = () => {
  return axios.post(
    '/perms/logout',
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  )
}

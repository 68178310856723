import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import LayoutWithHeaderV2 from '@/layout/LayoutWithHeaderV2.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getUrl, hasPermission, handleJumpToLogin } from '@/untils'
import { store } from '@/store'
import { apiGetRefreshToken } from '@/views/admin/service'
import { computed } from 'vue'
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/home',
  //   component: Layout,
  //   redirect: '/home/main',
  //   meta: { title: '总览', icon: 'iconfont icon-yemian-copy-copy-copy', showSubMenu: false, menu: true },
  //   children: [
  //     {
  //       path: 'main',
  //       name: 'home',
  //       component: () => import('@/views/home/index.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/video',
  //   component: Layout,
  //   redirect: '/video/main',
  //   meta: { title: '视频管理', icon: 'iconfont icon-shipin', showSubMenu: false, menu: true },
  //   children: [
  //     {
  //       path: 'main',
  //       name: 'mainVideo',
  //       meta: { title: '实时视频' },
  //       component: () => import('@/views/video/realtime.vue')
  //     },
  //     {
  //       path: 'videotap',
  //       name: 'videotap',
  //       meta: { title: '录像' },
  //       component: () => import('@/views/video/videotap.vue')
  //     },
  //     {
  //       path: 'polling',
  //       name: 'polling',
  //       meta: { title: '轮询' },
  //       component: () => import('@/views/video/polling.vue')
  //     },
  //     {
  //       path: 'screenshots',
  //       name: 'screenshots',
  //       meta: { title: '截图' },
  //       component: () => import('@/views/video/screenshots.vue')
  //     },
  //     {
  //       path: 'decoder',
  //       name: 'decoder',
  //       meta: { title: '电视墙' },
  //       component: () => import('@/views/video/decoder/decoder.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/ins',
  //   component: Layout,
  //   redirect: '/ins/insManual',
  //   meta: { title: '巡视管理', icon: 'iconfont icon-gaojing01-01', showSubMenu: true, menu: true },
  //   children: [
  //     {
  //       path: 'insManual',
  //       name: 'insManual',
  //       meta: { title: '手动巡视', icon: 'iconfont icon-gaojing01-01' },
  //       component: () => import('@/views/ins/insManual/insManual.vue')
  //     },
  //     {
  //       path: 'insTask',
  //       name: 'insTask',
  //       meta: { title: '巡视任务', icon: 'iconfont icon-gaojing01-01' },
  //       component: () => import('@/views/ins/insTask/inTaskLayout.vue'),
  //       redirect: '/ins/insTask/insTaskMain',
  //       children: [
  //         {
  //           path: 'insTaskMain',
  //           name: 'insTaskMain',
  //           meta: { title: '任务列表', icon: 'iconfont icon-gaojing01-01' },
  //           component: () => import('@/views/ins/insTask/insTask.vue')
  //         },
  //         {
  //           path: 'insTaskDetail',
  //           name: 'insTaskDetail',
  //           meta: { title: '巡视报告', icon: 'iconfont icon-gaojing01-01' },
  //           component: () => import('@/views/ins/insTask/insTaskReport.vue')
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/safe',
  //   component: Layout,
  //   redirect: '/safe/safe',
  //   meta: { title: '输煤监测', icon: 'iconfont icon-AI-01', showSubMenu: false, menu: true },
  //   children: [
  //     {
  //       path: 'safe',
  //       name: 'safe',
  //       meta: { title: '输煤监测' },
  //       component: () => import('@/views/safe/safe.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/alarm',
  //   component: Layout,
  //   redirect: '/alarm/alarmManager',
  //   meta: { title: '告警中心', icon: 'iconfont icon-gaojing01-01', showSubMenu: false, menu: true },
  //   children: [
  //     {
  //       path: 'alarmManager',
  //       name: 'alarmManager',
  //       meta: { title: '告警管理' },
  //       component: () => import('@/views/alert/index.vue')
  //     },
  //     {
  //       path: 'alarmStatistics',
  //       name: 'alarmStatistics',
  //       meta: { title: '告警统计' },
  //       component: () => import('@/views/alert/alarmStatistics.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/hot',
  //   component: Layout,
  //   redirect: '/hot/main',
  //   meta: { title: '热成像管理', icon: 'iconfont icon-xiaofang1', showSubMenu: false, menu: false },
  //   children: [
  //     {
  //       path: 'hot-history',
  //       name: 'hot-history',
  //       component: () => import('@/views/hot/components/history.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/operations',
  //   component: Layout,
  //   redirect: '/operations/main',
  //   meta: { title: '运维管理', icon: 'iconfont icon-yunwei-01', showSubMenu: false, menu: false },
  //   children: [
  //     {
  //       path: 'main',
  //       name: 'main',
  //       meta: { title: '设备监控', icon: 'iconfont icon-yunwei-01' },
  //       component: () => import('@/views/operations/deviceMonitor.vue')
  //     },
  //     {
  //       path: 'deviceoperations',
  //       name: 'deviceoperations',
  //       meta: { title: '设备运维', menu: true },
  //       component: () => import('@/views/operations/deviceoperations.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/systemc',
  //   name: 'system',
  //   component: Layout,
  //   meta: { icon: 'iconfont icon-xitong2', title: '系统管理', menu: true },
  //   redirect: '/systemc/systemchild',
  //   children: [
  //     {
  //       path: 'systemchild',
  //       name: 'systemchild',
  //       meta: { title: '系统管理' },
  //       component: () => import('@/views/system/index.vue')
  //     }
  //   ]
  // },
  {
    path: '/login',
    name: 'login',
    meta: { icon: 'iconfont icon-xunjianjilu', title: '登录', menu: false },
    component: () => import('@/views/admin/login.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    meta: { icon: 'iconfont icon-xunjianjilu', title: '无权限', menu: false },
    component: () => import('@/views/admin/unauthorized.vue')
  },
  // {
  //   path: '/',
  //   meta: { menu: false },
  //   redirect: '/home/main'
  // }
]

/**
 * 深度map
 * @param arr
 * @param callback
 */
const deepMap = (arr: any[], callback: (item: any, index: number, array: any[]) => any) => {
  return arr.map((item, index, array) => {
    if (item['children']?.length) {
      item['children'] = deepMap(item['children'], callback)
    }
    return callback(item, index, array)
  })
}

const findFirstRoute = (routes: Array<any>, key: string = 'name') => {
  let routeName = ''
  if (routes.length) {
    if (routes[0]['children']?.length) {
      routeName = findFirstRoute(routes[0]['children'], key)
    } else {
      routeName = routes[0][key] || ''
    }
  }
  return routeName
}
const module = import.meta.glob('@/views/**')
// 路由白名单
const white = ['login', 'unauthorized', '/login', '/unauthorized']

export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
  // routes: [],
})
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})
router.beforeEach(async (to, form, next) => {
  const params = getUrl(location.search || location.href) || {}
  // 拦截token
  if (params.platformtoken) {
    const authorization: any = to.query.id_token || params.id_token || params.platformtoken
    // 双token无感刷新
    store.commit('SET_TOKEN', authorization)
    store.commit('SET_PLATFORMTOKEN', true)
    localStorage.setItem('isPlatformtoken', 'true')
    localStorage.setItem('authorization', authorization)
    // 获取refresh_token
    const result = await apiGetRefreshToken({
      authorization: authorization
    })
    if (result.data.code == 0) {
      store.commit('SET_REFRESH_TOKEN', result.data?.data?.refreshToken)
      localStorage.setItem('refreshToken', result.data?.data?.refreshToken)
    }
  }
  // 需要使用权限的页面需要判断是否已经获取过权限
  if (!(white.includes((to.name || '') as string) || white.includes((to.path || '') as string)) && !store.state.user.hasGetPermission) {
    try {
      // 获取权限、用户、角色等相关信息
      await store.dispatch('getUserInfo')
      // 处理动态路由 ==> 页面组件
      const res = await store.dispatch('getRoutes')
      const routes = deepMap(res, (d: any, index: number, arr: any[]) => {
        if (d.component) {
          if (d.component === '@/layout/index.vue') {
            d.component = Layout
          } else if(d.component === '@/layout/LayoutWithHeaderV2.vue') {
            d.component = LayoutWithHeaderV2
          } else {
            d.component = module[`/src/views/${d.component}`] || module[`/src/views/errorMessage/404.vue`]
          }
        }
        return d
      })
      routes.forEach((d) => {
        router.addRoute(d)
      })
      store.commit('SET_HAS_GET_PERMISSON', true)
      // 处理只输入ip地址的重定向问题, 默认重定向到有权限的第一个页面
      if (to.path === '/' && !to.matched?.length) {
        const firstRoute = findFirstRoute(routes, 'name')
        if (firstRoute) {
          router.addRoute({
            path: '/',
            meta: { menu: false },
            redirect: { name: firstRoute }
          })
          next({ name: firstRoute })
          return
        } else {
          next({ path: '/unauthorized' })
          return
        }
      }
      // 注册好路由后跳转到对应的页面，会重新触发beforeEach
      next({ 
        path: to.fullPath,
        query: to.query
      })
      return
    } catch (err) {
      console.log('处理动态路由失败', err)
    }
  }

  const hasOwnPermission = hasPermission(to.path, store.state.user.routes)

  if (hasOwnPermission || white.includes((to.name || '') as string) || white.includes((to.path || '') as string)) {
    next()
  } else {
    next({ path: '/unauthorized' })
  }
  // const title: any = to.meta.title || ''
  // document.title = title
  document.title = '智慧巡检'
  NProgress.start()
  return true
})

router.afterEach(() => {
  NProgress.done()
})
export default router
export { routes }

<template>
  <div id="index" class="main" :class="{ main_home: ['/home/main'].includes(route?.fullPath) }">
    <div class="main-header">
      <LayoutHeader1></LayoutHeader1>
    </div>
    <div class="nav" v-if="hasNav">
      <TySecondaryNav />
    </div>
    <div class="main-container">
      <router-view v-slot="{ Component }" v-if="route.meta.noCache">
        <transition name="slide-left" mode="out-in">
          <div class="aaa" style="width: 100%;height: 100%;">
            <component :is="Component" :key="route.name" />
          </div>
        </transition>
      </router-view>
      <router-view v-slot="{ Component }">
        <transition name="slide-left" mode="out-in">
          <div class="bbb" style="width: 100%;height: 100%;" v-show="route?.meta?.noCache == false">
            <keep-alive>
              <component :is="Component" :key="route.name" v-if="route.meta.noCache == false" />
            </keep-alive>
          </div>
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import LayoutHeader1 from './LayoutHeaderV1.vue'
import LayoutHeader2 from './LayoutHeaderV2.vue'
import { useStore } from 'vuex'
import TySecondaryNav from '@/components/TySecondaryNav/index.vue'
import { useRoute, useRouter } from 'vue-router'
import { Search, Hide, SwitchButton, ArrowDown } from '@element-plus/icons-vue'


export default defineComponent({
  components: { LayoutHeader1, LayoutHeader2, TySecondaryNav, ArrowDown, Search },
  async setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    // @ts-ignore
    const hasNav = computed(() => route.matched[0]['children']?.filter(d => d.hidden === false).length)

    return {
      hasNav,
      route
    }
  }
})
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  display: flex;
  flex-direction: column;

  .main-header {
    position: relative;
    box-sizing: border-box;
    height: 100px;
    text-align: center;
    background-color: #141b28;
  }

  .main-container {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }

  .main-bottom {
    width: 100%;
    height: 30px;
    background: url('../assets/ui-image/bottom1.png') no-repeat left bottom;
    background-size: 100% 100%;
  }
}

.main_home {
  background: url('@/assets/ui-image/zhengning/bg3.png') no-repeat center center;
  background-size: 100% 100%;

  .main-header {
    background-color: transparent;
  }
}

/* 页面切换动画-s */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.3s ease;
}

// slide-right
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

// slide-left
.slide-left-enter-from {
  @extend.slide-right-leave-to;
}

.slide-left-leave-to {
  @extend.slide-right-enter-from;
}

.nav {
  padding: 0 10px 0 10px;
  background-color: #141b28;
}
</style>

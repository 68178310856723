import { mqttConnect, Client } from '@/service/mqtt'
// const MQTT_SERVICE = import.meta.env.VITE_APP_BASE_MQTT
const host = window.location.hostname
const MQTT_SERVICE = import.meta.env.MODE == 'production' ? 'wss://' + host + '/wssocket/ws' : import.meta.env.VITE_APP_BASE_MQTT
const { VITE_APP_BASE_MQTT_USERNAME, VITE_APP_BASE_MQTT_PASSWORD } = import.meta.env
const theme: string[] = [
  '/insResult/result',
  '/ins/result/progress', //巡视进度
  '/ins/result/detail', //巡视进度
  '/insDetail',
  '/ins/result/point', //手动巡视步骤
  '/ins/insResultPointState',
  '/access/call',
  '/donghuanDeviceInfo',
  '/guard/report/entranceGuard',
  '/access/report/answerResponse',
  '/ai/report/algArm', //告警视频
  '/alarmCenter/report/alarm',
  '/v1/+/service/commandResponse',
  '/access/control',
  '/alarmCenter/alarm/realTime'
]
const MqttModule = {
  state: () => ({
    mqtt: 'sdfsadf',
    person: [],
    executeProcess: [],
    taskResult: [], //
    water_level: localStorage.getItem('water_level') ? JSON.parse(localStorage.getItem('water_level') || '') : '',
    radioAlarm: null,
    insResultData: {},
    insManualData: {},
    insManualPointsData: {},
    insProgress: {},
    resultDetail: {},
    ringData: {},
    donghuanDeviceInfo: [],
    entranceGuard: {}, //门禁信息
    doorDevice: [],
    doorData: {}, //需要播放视频的门禁
    answerResponse: {}, // 门禁呼叫信息
    supertask: {}, // 智能监督发任务
    algArm: {},
    algarmCenter: {}, //
    alarmCenter: {}, //告警中心告警信息
    accType: {}
  }),
  mutations: {
    message(state: any, data: any) {
      try {
        let { top, message } = data
        let messages = typeof message == 'string' ? JSON.parse(message) : message
        // console.log(messages, 'messages')
        if (top == '/insResult/result') {
          state.insResultData = messages
          state.insProgress = messages
        } else if (top == '/insDetail') {
          state.resultDetail = messages
        } else if (top == '/ins/result/point') {
          state.insManualPointsData = messages
        } else if (top == '/access/call') {
          state.ringData = messages
        } else if (top == '/donghuanDeviceInfo') {
          console.log('动环', messages)
          state.donghuanDeviceInfo = messages
        } else if (top == '/guard/report/entranceGuard') {
          state.entranceGuard = messages
        } else if (top == '/access/report/answerResponse') {
          state.answerResponse = messages
        } else if (top == '/ai/report/algArm') {
          state.algarmCenter = messages
        } else if (top == '/alarmCenter/alarm/realTime') {
          state.alarmCenter = messages
        } else if (top == '/ins/result/progress') {
          state.insProgress = messages
        } else if (top == '/ins/insResultPointState') {
          state.insManualData = messages
        } else if (/\/v1\/\d+\/service\/commandResponse/.test(top)) {
          // state.supertask = JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(message)))
          state.supertask = messages
        } else if (top == '/access/control') {
          state.accType = messages
        }
      } catch (error) {
        console.log(error)
      }
    },
    setExecuteProcess(state: any, id: number) {
      let grouId = state.executeProcess.map((item: { id: any }) => item.id)
      let index = grouId.indexOf(id.toString())

      if (index != -1) {
        state.executeProcess.splice(index, 1)
        console.log('setExecuteProcess', state.executeProcess, id, grouId, index)
      }
    },
    setExecuteProcessDel(state: any) {
      state.executeProcess = []
    },
    setRadioAlarm(state: any, data: any) {
      state.radioAlarm = data
    },
    setDoorDevice(state: any, data: any) {
      state.doorDevice = data
    },
    setDoorData(state: any, data: any) {
      state.doorData = data
    }
  },
  actions: {
    mqttClient() {
      return new Promise((resolve: any) => {
        mqttConnect(MQTT_SERVICE, theme, {
          userName: VITE_APP_BASE_MQTT_USERNAME || 'teamway',
          password: VITE_APP_BASE_MQTT_PASSWORD || 'Teamway@123'
        }).then((res: any) => {
          resolve(true)
        })
      })
    },
    mqttMessage({ commit }: any) {
      Client.onMessageArrived = (messages: any) => {
        let top = messages?.destinationName
        let message = messages.payloadString
        commit('message', { top, message })
      }
    },
    pushMessage({}, data: { theme: string; message: Object }) {
      Client.publish(data.theme, JSON.stringify(data.message))
    }
  }
}
// 语音播报的函数
const handleSpeak = (text: any) => {
  const filterText = text.match(/[a-zA-Z0-9\u4e00-\u9fa5\-\.]+/g).join('')
  console.log('filterText', filterText)
  const msg = new SpeechSynthesisUtterance(filterText)
  msg.lang = 'zh' // 使用的语言:中文
  msg.volume = 100 // 声音音量：1
  msg.rate = 1 // 语速：1
  msg.pitch = 1 // 音高：1
  window.speechSynthesis.speak(msg)
  // return msg
}

export default MqttModule

<template>
  <div id="index" class="main">
    <div>
      <LayoutHeader2></LayoutHeader2>
    </div>
    <div class="nav" v-if="isNav">
      <TySecondaryNav />
    </div>
    <div class="main-container">
      <router-view v-slot="{ Component }">
        <transition name="slide-left" mode="out-in">
          <keep-alive v-show="route?.meta?.noCache == false">
            <component :is="Component" :key="route.name" v-if="route?.meta?.noCache == false" />
          </keep-alive>
        </transition>
      </router-view>
      <router-view v-slot="{ Component }" v-if="route?.meta?.noCache">
        <transition name="slide-left" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </router-view>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import LayoutHeader2 from './LayoutHeaderV2.vue'
import TySecondaryNav from '@/components/TySecondaryNav/index.vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const store = useStore()
const route = useRoute()
const router = useRouter()
const isNav = computed(() => route.matched[0]['children'].length > 1)

</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  display: flex;
  flex-direction: column;


  .main-container {
    box-sizing: border-box;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
}

/* 页面切换动画-s */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.3s ease;
}

// slide-right
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

// slide-left
.slide-left-enter-from {
  @extend.slide-right-leave-to;
}

.slide-left-leave-to {
  @extend.slide-right-enter-from;
}

.nav {
  padding: 0 10px 0 10px;
  background-color: #141b28;
}
</style>

import axios from '@/plugins/axios'
// 告警
export const selectPageList = (data: any) => {
  return axios.post('/base/alarmCenter/selectPageList', data)
}

// 告警关注、取消关注
export const apiFollowAlarm = (data: { alarmCenterIds: Array<string>, followStatus: 1 | 2 }) => {
  return axios.post('/base/alarmCenter/followOrNnfollow', data, { noMessage: true } as any)
}

// 动环告警
export const deviceSelectPageList = (data: any) => {
  return axios.post('/base/deviceAlarm/selectPageList', data)
}

// 巡视查询
export const insSelectPageList = (data: any) => {
  return axios.post('/ins/insResultDetail/selectPageList', data)
}
//算法统计
export const algAlarmCal = () => {
  return axios.get('/base/algAlarm/cal')
}

//动环统计
export const deviceAlarmCal = () => {
  return axios.get('/base/deviceAlarm/cal')
}
//门禁统计
export const entranceCal = () => {
  return axios.get('/base/entrance/cal')
}
//巡视统计
export const insResultDetailCal = () => {
  return axios.get('/ins/insResultDetail/cal')
}
// 消防告警
export const fireAlarm = (data: any) => {
  return axios.post('/base/fireAlarm/selectPageList', data)
}

// 告警确认
export const algAlarmConfirmState = (data: any) => {
  return axios({
    url: `/base/alarmCenter/confirm`,
    method: 'post',
    noMessage: true,
    data
  } as any)
}
// 获取指定周期的告警数及增长率
// type 0：区域 1：设备
// nodeName: 节点名称
export const apiGetAlarmCount = (data: { nodeName: string, type: string }) => {
    return axios({
        url: `/base/AlarmStatistics/getAlarmCount`,
        method: 'get',
        noMessage: true,
        params: data
    } as any)
}
// 获取区域下的告警并按算法名称分组统计
export const apiGetAlgorithmAlarmStatistics = (data: { nodeName: string, alarmTypeId: string , startTime: string, endTime: string}) => {
    return axios({
        url: `/base/AlarmStatistics/getAlgorithmAlarmStatistics`,
        method: 'get',
        noMessage: true,
        params: data
    } as any)
}
// 获取区域下的告警并按月份分组统计
export const apiGetMonthAlarmStatistics = (data: { nodeName: string, alarmTypeId: string , startTime: string, endTime: string}) => {
    return axios({
        url: `/base/AlarmStatistics/getMonthAlarmStatistics`,
        method: 'get',
        noMessage: true,
        params: data
    } as any)
}
// 统计数据
export const statisticsByAlgorithmType = (data: any) => {
  return axios({
    url: `/base/alarmCenter/statisticsByAlgorithmType`,
    method: 'post',
    data
  } as any)
}

///alarmCenter/getAlarmType //获取告警类型
export const getAlarmType = () => {
  return axios.get('/base/alarmCenter/getAlarmType')
}


export const apiBatchDelAlarm = (data: any) => {
  return axios.post(`/base/alarmCenter/delete`, data)
}

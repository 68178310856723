export default {
  defaultTheme: {
    'color-scheme': 'light',
    '--el-color-white': '#ffffff',
    '--el-color-black': '#000000',
    '--el-color-primary': '#409eff',
    '--el-color-primary-light-3': '#79bbff',
    '--el-color-primary-light-5': '#a0cfff',
    '--el-color-primary-light-7': '#c6e2ff',
    '--el-color-primary-light-8': '#d9ecff',
    '--el-color-primary-light-9': '#ecf5ff',
    '--el-color-primary-dark-2': '#337ecc',
    '--el-color-success': '#67c23a',
    '--el-color-success-light-3': '#95d475',
    '--el-color-success-light-5': '#b3e19d',
    '--el-color-success-light-7': '#d1edc4',
    '--el-color-success-light-8': '#e1f3d8',
    '--el-color-success-light-9': '#f0f9eb',
    '--el-color-success-dark-2': '#529b2e',
    '--el-color-warning': '#e6a23c',
    '--el-color-warning-light-3': '#eebe77',
    '--el-color-warning-light-5': '#f3d19e',
    '--el-color-warning-light-7': '#f8e3c5',
    '--el-color-warning-light-8': '#faecd8',
    '--el-color-warning-light-9': '#fdf6ec',
    '--el-color-warning-dark-2': '#b88230',
    '--el-color-danger': '#f56c6c',
    '--el-color-danger-light-3': '#f89898',
    '--el-color-danger-light-5': '#fab6b6',
    '--el-color-danger-light-7': '#fcd3d3',
    '--el-color-danger-light-8': '#fde2e2',
    '--el-color-danger-light-9': '#fef0f0',
    '--el-color-danger-dark-2': '#c45656',
    '--el-color-error': '#f56c6c',
    '--el-color-error-light-3': '#f89898',
    '--el-color-error-light-5': '#fab6b6',
    '--el-color-error-light-7': '#fcd3d3',
    '--el-color-error-light-8': '#fde2e2',
    '--el-color-error-light-9': '#fef0f0',
    '--el-color-error-dark-2': '#c45656',
    '--el-color-info': '#909399',
    '--el-color-info-light-3': '#b1b3b8',
    '--el-color-info-light-5': '#c8c9cc',
    '--el-color-info-light-7': '#dedfe0',
    '--el-color-info-light-8': '#e9e9eb',
    '--el-color-info-light-9': '#f4f4f5',
    '--el-color-info-dark-2': '#73767a',
    '--el-bg-color': '#ffffff',
    '--el-bg-color-page': '#f2f3f5',
    '--el-bg-color-overlay': '#ffffff',
    '--el-text-color-primary': '#303133',
    '--el-text-color-regular': '#606266',
    '--el-text-color-secondary': '#909399',
    '--el-text-color-placeholder': '#a8abb2',
    '--el-text-color-disabled': '#c0c4cc',
    '--el-border-color': '#526D96',
    '--el-border-color-light': '#e4e7ed',
    '--el-border-color-lighter': '#ebeef5',
    '--el-border-color-extra-light': '#f2f6fc',
    '--el-border-color-dark': '#d4d7de',
    '--el-border-color-darker': '#cdd0d6',
    '--el-fill-color': '#f0f2f5',
    '--el-fill-color-light': '#f5f7fa',
    '--el-fill-color-lighter': '#fafafa',
    '--el-fill-color-extra-light': '#fafcff',
    '--el-fill-color-dark': '#ebedf0',
    '--el-fill-color-darker': '#e6e8eb',
    '--el-fill-color-blank': '#ffffff',
    '--el-box-shadow': '0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08)',
    '--el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, .12)',
    '--el-box-shadow-lighter': '0px 0px 6px rgba(0, 0, 0, .12)',
    '--el-box-shadow-dark': '0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16)',
    '--el-disabled-bg-color': 'var(--el-fill-color-light)',
    '--el-disabled-text-color': 'var(--el-text-color-placeholder)',
    '--el-disabled-border-color': 'var(--el-border-color-light)',
    '--el-overlay-color': 'rgba(0, 0, 0, .8)',
    '--el-overlay-color-light': 'rgba(0, 0, 0, .7)',
    '--el-overlay-color-lighter': 'rgba(0, 0, 0, .5)',
    '--el-mask-color': 'rgba(255, 255, 255, .9)',
    '--el-mask-color-extra-light': 'rgba(255, 255, 255, .3)',
    '--el-border-width': '1px',
    '--el-border-style': 'solid',
    '--el-border-color-hover': '#0085ff',
    '--el-border': 'var(--el-border-width) var(--el-border-style) var(--el-border-color)',
    '--el-svg-monochrome-grey': 'var(--el-border-color)',
  },
  darkTheme: {
    'color-scheme': 'dark',
    '--el-color-white': '#FFFFFF', // 基础浅色
    '--el-color-black': '#012447', // 基础深色
    '--el-color-primary': '#409eff',
    '--el-color-primary-light-3': '#79bbff',
    '--el-color-primary-light-5': '#a0cfff',
    '--el-color-primary-light-7': '#c6e2ff',
    '--el-color-primary-light-8': '#d9ecff',
    '--el-color-primary-light-9': '#ecf5ff',
    '--el-color-primary-dark-2': '#337ecc',
    '--el-color-success': '#67c23a',
    '--el-color-success-light-3': '#95d475',
    '--el-color-success-light-5': '#b3e19d',
    '--el-color-success-light-7': '#d1edc4',
    '--el-color-success-light-8': '#e1f3d8',
    '--el-color-success-light-9': '#f0f9eb',
    '--el-color-success-dark-2': '#529b2e',
    '--el-color-warning': '#e6a23c',
    '--el-color-warning-light-3': '#eebe77',
    '--el-color-warning-light-5': '#f3d19e',
    '--el-color-warning-light-7': '#f8e3c5',
    '--el-color-warning-light-8': '#faecd8',
    '--el-color-warning-light-9': '#fdf6ec',
    '--el-color-warning-dark-2': '#b88230',
    '--el-color-danger': '#f56c6c',
    '--el-color-danger-light-3': '#f89898',
    '--el-color-danger-light-5': '#fab6b6',
    '--el-color-danger-light-7': '#fcd3d3',
    '--el-color-danger-light-8': '#fde2e2',
    '--el-color-danger-light-9': '#fef0f0',
    '--el-color-danger-dark-2': '#c45656',
    '--el-color-error': '#f56c6c',
    '--el-color-error-light-3': '#f89898',
    '--el-color-error-light-5': '#fab6b6',
    '--el-color-error-light-7': '#fcd3d3',
    '--el-color-error-light-8': '#fde2e2',
    '--el-color-error-light-9': '#fef0f0',
    '--el-color-error-dark-2': '#c45656',
    '--el-color-info': '#909399',
    '--el-color-info-light-3': '#b1b3b8',
    '--el-color-info-light-5': '#c8c9cc',
    '--el-color-info-light-7': '#dedfe0',
    '--el-color-info-light-8': '#e9e9eb',
    '--el-color-info-light-9': '#f4f4f5',
    '--el-color-info-dark-2': '#73767a',
    '--el-bg-color': 'rgba(22,32,51,0.8)',
    '--el-bg-color-page': '#1B3651',
    '--el-bg-color-overlay': '#1B3651',
    '--el-text-color-primary': '#FFFFFF', // 主要文字颜色
    '--el-text-color-regular': '#FFFFFF', // 常规文字颜色
    '--el-text-color-placeholder': '#9999', // 占位文字颜色
    '--el-text-color-secondary': '#FFFFFF', // 次要文字颜色
    '--el-text-color-disabled': '#c0c4cc',
    '--el-border-color':'#1B3161',
    '--el-border-color-light': '#388DE0', // 二级边框颜色
    '--el-border-color-lighter': '#58A9F5', // 三级边框颜色
    '--el-border-color-extra-light': '#86B3F9', // 四级边框颜色
    '--el-border-color-dark': '#d4d7de',
    '--el-border-color-darker': '#cdd0d6',
    '--el-fill-color': '#012447',
    '--el-fill-color-light': '#1B3651',
    '--el-fill-color-lighter': '#1B3651',
    '--el-fill-color-extra-light': '#1B3651',
    '--el-fill-color-dark': '#1B3651',
    '--el-fill-color-darker': '#1B3651',
    '--el-fill-color-blank': 'transparent',
    '--el-box-shadow': '0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08)',
    '--el-box-shadow-light': '0px 0px 12px rgba(0, 0, 0, .12)',
    '--el-box-shadow-lighter': '0px 0px 6px rgba(0, 0, 0, .12)',
    '--el-box-shadow-dark': '0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16)',
    '--el-disabled-bg-color': 'var(--el-fill-color-light)',
    '--el-disabled-text-color': 'var(--el-text-color-placeholder)',
    '--el-disabled-border-color': 'var(--el-border-color-light)',
    '--el-overlay-color': 'rgba(0, 0, 0, .8)',
    '--el-overlay-color-light': 'rgba(0, 0, 0, .7)',
    '--el-overlay-color-lighter': 'rgba(0, 0, 0, .5)',
    '--el-mask-color': 'rgba(255, 255, 255, .9)',
    '--el-mask-color-extra-light': 'rgba(255, 255, 255, .3)',
    '--el-border-width': '1px',
    '--el-border-style': 'solid',
    '--el-border-color-hover': '#0085ff',
    '--el-border': 'var(--el-border-width) var(--el-border-style) var(--el-border-color)',
    '--el-svg-monochrome-grey': 'var(--el-border-color)',
    '--el-checkbox-input-border':'red',
  }
}

/*
 * @Descripttion: 
 * @version: 
 * @Author: huangjunjie
 * @Date: 2024-04-01 14:23:51
 * @LastEditors: huangjunjie
 * @LastEditTime: 2024-05-10 09:17:01
 */
import axios from '@/plugins/axios'
// 获取logo.标题
export const selectSysConfig = () => {
  return axios.get('/base/sysConfig/selectSysConfig')
}

// 获取路由信息
export const apiGetRoutes = () => {
  return axios.get('/perms/getRouters')
}

// 修改密码
export const apiUpdatePwd = (data: Record<'oldPassword' | 'newPassword', string>) => {
  const url = `/perms/system/user/profile/updatePwd?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}`
  return axios.put(url)
}

export const apiGetAlarmType = () => {
  return axios.get('/base/alarmCenter/getAlarmType')
}

/**
 * 保存配置
 */
export const apiSaveSysConfig = (data: any) => {
  return axios.post('/base/sysConfig/saveSysConfig', data)
}

/**
 * 不分页查询所有告警
 * @param data
 * @returns
 */
export const apiGetAlarmAll = (data: any) => {
  return axios.post('/base/alarmCenter/alarmList', data)
}
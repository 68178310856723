import { createApp } from 'vue'
import App from './App.vue'
// import 'ant-design-vue/dist/antd.css';
// import './antdesign.theme.css';
import 'element-plus/dist/index.css'
import './element-variables.scss'
// import initElement from '@/plugins/element'
import initVxTable from '@/plugins/vx-table'
// import initVueChart from '@/plugins/vue-chart'
import initDate from '@/directives'
import initStore from '@/store/index'
// import installDatav from './plugins/datav'
// import initIcon from './plugins/Icon'
import router from './router'
import MyCard from '@/components/card.vue'
// import ElSelectTree from 'el-select-tree';
import '@/assets/icon-font/iconfont.css'
import '@/assets/icon-font-20230807/iconfont.css'
import '@/plugins/axios'
import './common.scss'
import 'animate.css'
import 'swiper/css'
// import "//
// import 'swiper/swiper.scss'
// import lottie from 'vue-lottie'
// import Vue3DraggableResizable from 'vue3-draggable-resizable'
//需引入默认样式
// import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)
import SvgIcon from '@/components/svg/index'
// loginToken()
const app = createApp(App)
app.use(router)
// app.use(Vue3DraggableResizable)
app.component('MyCard', MyCard)
// app.component('lottie', lottie)
SvgIcon(app)
// initElement(app)
initVxTable(app)
// initVueChart(app)
// installDatav(app)
initStore(app)
// initIcon(app)
app.mount('#app')
initDate(app)
